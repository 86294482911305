export const allStatuses = {
  available: 'Available',
  charging: 'Charging',
  preparing: 'Preparing',
  suspendedevse: 'SuspendedEVSE',
  suspendedev: 'SuspendedEV',
  finishing: 'Finishing',
  reserved: 'Reserved',
  unavailable: 'Unavailable',
  faulted: 'Faulted',
  offline: 'Offline',
  unknown: 'Unknown',
};

export type SimplifiedStatus =
  'Available'
  | 'Charging'
  | 'Reserved'
  | 'Unavailable'
  | 'Faulted'
  | 'Offline'
  | 'Unknown'
export const simplifiedStatuses: {[key: string]: SimplifiedStatus} = {
  available: 'Available',
  charging: 'Charging',
  reserved: 'Reserved',
  unavailable: 'Unavailable',
  faulted: 'Faulted',
  offline: 'Offline',
  unknown: 'Unknown',
};

export const chargingStatuses = [
  'Preparing',
  'Finishing',
  'SuspendedEVSE',
  'SuspendedEV',
];
