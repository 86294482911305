import colors from '@/constants/colors';


export const issueStatuses = {
  OPEN: 'open',
  ONGOING: 'in_progress',
  CLOSED: 'closed',
};

export const issueColors = {
  OPEN: colors.red,
  ONGOING: colors.yellow,
  CLOSED: colors.green,
};

export const issueSeverities = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
};
