import React, { Component } from 'react';
import styles from './circularLoadingIndicator.scss';


interface IProps {
  size?: string | number,
  flat?: boolean,
}

/** CircularLoadingIndicator should center itself in parent */
class CircularLoadingIndicator extends Component<IProps> {
  static defaultProps = {
    size: '60px',
    flat: false,
  };

  render() {
    const { size, flat, ...otherProps } = this.props;
    const readySize = typeof size === 'string' ? size : `${size}px`;

    return (
      <div className={styles.spinnerContainer} style={flat ? { height: readySize } : {}}>
        <svg
          className={styles.spinner}
          width={readySize}
          height={readySize}
          viewBox="0 0 60 60"
          {...otherProps}
        >
          <circle fill="none" strokeWidth="4" strokeLinecap="round" cx="30" cy="30" r="28" />
        </svg>
      </div>
    );
  }
}

export default CircularLoadingIndicator;
