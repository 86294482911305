import { fetchFromEnelionApi } from '@/apiUtilities/axiosFunctions';
import { ArgsForFetchingFunction } from '@/typesAndInterfaces/miscellaneous';
import { Tenant } from '@/typesAndInterfaces/tenant';


export function changePassword(...args: ArgsForFetchingFunction) {
  return fetchFromEnelionApi('put', '/api/user/profile/password', ...args);
}

export function checkPolicies() {
  return fetchFromEnelionApi('get', '/api/auth/check+policies', {}, { disableCatcher: true });
}

export function createLogin(...args: ArgsForFetchingFunction) {
  return fetchFromEnelionApi('post', '/api/auth/login', ...args);
}

export function createResendConfirmation(email: string) {
  return fetchFromEnelionApi('post', '/api/user/send+verification', { email })
    .catch(() => {});
}

export function registerRegular(token: string, ...args: ArgsForFetchingFunction) {
  return fetchFromEnelionApi('put', `/api/common/invitations/${token}`, ...args);
}

export function passwordReset(email: string) {
  return fetchFromEnelionApi('post', '/api/user/password+reset', { email });
}

export function passwordResetProceed(...args: ArgsForFetchingFunction) {
  return fetchFromEnelionApi('post', '/api/user/password+reset/proceed', ...args);
}

export function getTenants(...args: ArgsForFetchingFunction) {
  return fetchFromEnelionApi('get', '/api/common/tenants', ...args);
}

export function getTenantConfig(...args: ArgsForFetchingFunction) {
  return fetchFromEnelionApi('get', '/api/common/tenant', ...args);
}

export async function getCurrentTenant(...args: ArgsForFetchingFunction) {
  const config = await getTenantConfig(...args);
  if (config) {
    const currentTenantUuid = config.find((item: { name: string, value: string }) => item.name === 'tenant_uuid')?.value;
    if (currentTenantUuid) {
      const tenants: Tenant[] = await getTenants();
      return tenants.find(tenant => tenant.uuid === currentTenantUuid);
    }
  }
  return null;
}
