/* eslint-disable no-console */
import { store } from '@/redux/store';


export function addGlobalFilters(args) {
  const { globalFilters } = store.getState();
  const argsWithGlobalFilters = args;

  if (globalFilters.groups !== null) {
    argsWithGlobalFilters.parent_node = globalFilters.groups.uuid;
  }
  return argsWithGlobalFilters;
}

export function parseGetArgs(args) {
  const argsKeys = Object.keys(args);

  if (argsKeys.length === 0) return '';

  let argsAsString = '?';
  argsKeys.forEach((argName, argsIndex) => {
    if (argsIndex !== 0) argsAsString += '&';

    if (argName === 'filter') {
      for (let filterIndex = 0; filterIndex < args[argName].length; filterIndex += 1) {
        // eslint-disable-next-line prefer-const
        let [fieldName, operator, value] = args[argName][filterIndex];

        if (filterIndex !== 0) argsAsString += '&';

        switch (operator) {
          case '==':
            operator = 'eq';
            break;
          case '>=':
            operator = 'ge';
            break;
          case '>':
            operator = 'gt';
            break;
          case '<=':
            operator = 'le';
            break;
          case '<':
            operator = 'lt';
            break;
          default:
            break;
        }
        argsAsString += `filter=${fieldName};${operator};${value}`;
      }
    } else {
      argsAsString += `${argName}=${args[argName]}`;
    }
  });
  return argsAsString;
}
