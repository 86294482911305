import { chargingStatuses, simplifiedStatuses } from '@/constants/connectorStatuses';
import { profilePermissions } from '@/constants/permissions';
import i18n from '@/i18n';
import { countryPickerArray } from 'enelink-localization';
import { idbKeyval } from '@/utilities/indexedDb';
import { isExpired } from 'react-jwt';
import { apiURL } from '@/constants/apiConstants';
import showNoty from '@/utilities/showNoty';
import { languages, profileLanguages } from '@/constants/localisationData';
import { getTenantConfig } from '@/api/authentication';


export function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

export function getCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    // eslint-disable-next-line eqeqeq
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    // eslint-disable-next-line eqeqeq
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function normalizeNameForAvatar(rawName) {
  const suffixesToRemove = ['s.a.', 'sp. z o. o.', 'sp. z o.o.', 'sp.z o.o.'];
  const lowerCaseName = rawName.toLowerCase();
  let result = rawName;
  suffixesToRemove.forEach(suffix => {
    if (lowerCaseName.endsWith(suffix)) {
      result = rawName.slice(0, rawName.length - suffix.length);
    }
  });

  // initials generated by react-user-avatar don't work very well
  // with names made of just one capitalized word, i.e. EXAMPLE,
  // to alleviate that, we can manually capitalize first letter of each word,
  // and use that name to generate avatar

  const name = result.trim();
  const words = name.split(/[\s-]/);
  const capitalizedWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
  return capitalizedWords.join(' ');
}

export function join(rules) {
  return (value, data) => rules.map(rule => rule(value, data)).filter(error => !!error)[0];
}

export function normalizeUrl(url) {
  if (!url) {
    return url;
  }

  if (url.startsWith('http')) {
    return url;
  }

  return `http://${url}`;
}

export function getCurrentTenant() {
  return window.location.hostname.split('.')[0];
}

export function isProfileAdmin(data) {
  if (Array.isArray(data)) {
    return data.includes(profilePermissions.profileEdit);
  }
  return data?.permissions?.includes(profilePermissions.profileEdit);
}

export const isCompanyProfile = (profile) => profile?.profile_config?.profile_type === 'B2B'
  || ['Company', 'Corporation'].includes(profile?.profile_config?.name);

export function getProfileType(profile) {
  return profile?.profile_config.name?.split(' ')[0];
}

export const getProfileLabel = (profile) => {
  if (profile && !isCompanyProfile(profile)) {
    const user = (profile.users ?? [])[0];
    if (!user?.email) {
      return i18n.t('profileTypes:guest');
    }
    return user?.first_name ? `${user?.first_name} ${user?.last_name}` : user?.email;
  }
  return profile?.profile_company_detail?.name;
};


// returns deep copy of given object
export function clone(object) {
  return object === undefined ? undefined : JSON.parse(JSON.stringify(object));
}

export function equals(object1, object2) {
  return object1 === object2 || JSON.stringify(object1) === JSON.stringify(object2);
}

// wraps url params with '%', so backend can return records filtered by fragment of phrase
// e. g. https://forest.enelink.site/api/provider/users?first_name__ilike=%ola%
export function wildcards(param) {
  return `%25${encodeURIComponent(param)}%25`;
}

export function getCommonLabels(station) {
  return station.charging_points.map(point => point.operator_group_labels)
    .flatMap(label => label)
    .reduce((prev, current) => {
      const index = prev.findIndex(entity => entity.label.uuid === current.uuid);
      if (index >= 0) {
        // eslint-disable-next-line no-param-reassign
        prev[index].count += 1;
      } else {
        prev.push({ label: current, count: 1 });
      }
      return prev;
    }, [])
    .filter(entity => entity.count === station.charging_points.length)
    .map(entity => entity.label);
}


export function isCharging(point) {
  return point.ocpp_status === simplifiedStatuses.charging || chargingStatuses.includes(point.ocpp_status ?? '');
}

export function isEndUser(user) {
  return !user.isProvider && !user.isOperator;
}

export function replaceNullsWithEmptyStrings(requestBody) {
  if (!requestBody) {
    return null;
  }
  const newBody = requestBody;
  Object.keys(newBody).forEach(key => newBody[key] = (newBody[key] === null || newBody[key] === undefined) ? '' : newBody[key]);
  return newBody;
}

export const getCountryByCode = (code) => {
  const country = countryPickerArray.find(item => item.code === code);
  if (!country) {
    return code;
  }
  if (i18n.language === 'pl') {
    return country.name_pl;
  }
  return country.name_en;
};

export const getLanguageByCode = (code) => {
  const language = languages[code];
  if (!language) {
    return code;
  }
  return language;
};

export const getProfileLanguageByCode = (code) => {
  const language = profileLanguages[code];
  if (!language) {
    return code;
  }
  return language;
};

export const tokenIsValid = () => {
  const token = localStorage.getItem('sessionHeader');
  return !!token && !isExpired(token);
};

export const getTenantName = () => apiURL.split('.')[0].slice(8);

export const getTenantUuid = async () => {
  let config = await idbKeyval.get('tenantConfiguration');
  if (config) {
    return config.find(pair => pair.name === 'tenant_uuid')?.value;
  }
  try {
    config = await getTenantConfig();
    idbKeyval.set('tenantConfiguration', config);
    return config.find(pair => pair.name === 'tenant_uuid')?.value;
  } catch (e) {
    showNoty('error', JSON.stringify(e));
    return null;
  }
};

export const calculateMonthlyEnergy = (...values) => values
  .reduce((previousValue, currentValue) => {
    if (!currentValue || isNaN(+currentValue)) {
      return 0;
    }
    return previousValue * +currentValue;
  }, 1) / 100;
