import { Role } from '@/typesAndInterfaces/role';


interface Action {
  type: string,
  roles: Role[],
}

export const SET_PROFILE_ROLES = 'PROFILE_ROLES/SET_PROFILE_ROLES';

const initialState = {
  roles: [],
};

export default (
  state: { roles: Role[]|null } = initialState,
  action: Action,
) => {
  switch (action.type) {
    case SET_PROFILE_ROLES:
      return {
        ...state,
        roles: action.roles,
      };
    default:
      return state;
  }
};


export function setRoles(roles: Role[]) {
  return { type: SET_PROFILE_ROLES, roles };
}
