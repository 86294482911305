import { Station } from '@/typesAndInterfaces/station';


interface Action {
  type: string,
  stations: Station[]|null,
  chosenStation: Station | null,
}

export const SET_STATIONS = 'STATIONS/SET_STATIONS';
export const SET_CHOSEN_STATION = 'STATIONS/SET_CHOSEN_STATION';

const initialState = {
  stations: null,
  chosenStation: null,
};

export default (
  state: object = initialState,
  action: Action,
) => {
  switch (action.type) {
    case SET_STATIONS:
      return {
        ...state,
        stations: action.stations,
      };
    case SET_CHOSEN_STATION:
      return {
        ...state,
        chosenStation: action.chosenStation,
      };
    default:
      return state;
  }
};

export function setStations(stations: Station[]) {
  return { type: SET_STATIONS, stations };
}

export function setChosenStation(station: Station | null) {
  return { type: SET_CHOSEN_STATION, station };
}
