import { restrictTextLength } from '@/utilities/notationHelpers';


const SET_PAGE_NAME = 'NAVIGATION/SET_PAGE_NAME';
const CLEAR_PAGE_NAME = 'NAVIGATION/CLEAR_PAGE_NAME';
const SET_DATA = 'NAVIGATION/SET_DATA';
const CLEAR_DATA = 'NAVIGATION/CLEAR_DATA';

const initialState = {
  pageName: null,
  data: {},
};

export default function navigation(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_PAGE_NAME:
      return {
        ...state,
        pageName: restrictTextLength(action.pageName),
      };
    case CLEAR_PAGE_NAME:
      return {
        ...state,
        pageName: initialState.pageName,
      };
    case SET_DATA:
      return {
        ...state,
        data: action.data,
      };
    case CLEAR_DATA:
      return {
        ...state,
        data: initialState.data,
      };
    default:
      return state;
  }
}


export function setPageName(pageName) {
  return { type: SET_PAGE_NAME, pageName };
}

export function clearPageName() {
  return { type: CLEAR_PAGE_NAME };
}

export function setData(data) {
  return { type: SET_DATA, data };
}

export function clearData() {
  return { type: CLEAR_DATA };
}
