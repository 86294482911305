import brands from '@/constants/brands';


const brandingEnelion = {
  primary: '#037BF2',
  secondary: '#5D9EEE',
  accent: '#037BF2',
};

const brandingTauron = {
  primary: '#E2007A',
  secondary: '#EF5ECF',
  accent: '#E2007A',
};

const brandingPolenergia = {
  primary: '#071D49',
  secondary: '#00C389',
  accent: '#00AD7A',
};

const rawColors = {
  transparent: 'transparent',

  /* MAIN COLOR PALETTE */
  black_true: '#000000',
  black: '#272C31',
  black_light: '#353C43',

  grey: '#DBDBDA',
  grey_light: '#F5F5F5',
  grey_silver: '#A4ABB2',

  green: '#00C389',
  green_light: '#5BB75B',

  blue: '#037BF2',
  blue_light: '#5D9EEE',

  purple: '#9B51E0',
  purple_light: '#BB6BD9',

  red: '#e24646',
  red_light: '#f15f60',

  yellow: '#FF9900',

  white: '#fff',

  /* TABLE COLORS */
  table_gray_line: '#dbdbdb',
  table_search_background: '#EDEDED',

  /* TAGS COLORS */
  tag_white_text: '#fff',
  tag_black_text: '#010000',

  /* modal_background */
  modal_background: 'rgba(0, 0, 0, 0.5)',

  /* BUTTONS COLORS */
  button_grey: '#ebebeb',
  button_grey_hover: '#c4c4c4',
  button_grey_border: '#a4a4a4',

  button_black: '#333333',
  button_black_hover: '#000',

  button_salmon: '#f58080',
  button_salmon_hover: '#F26060',

  button_disable_border: '#bcbcbc',

};

const getBranding = () => {
  switch (process.env.BRANDING) {
    case brands.ENELION:
      return brandingEnelion;
    case brands.TAURON:
      return brandingTauron;
    case brands.POLENERGIA:
      return brandingPolenergia;
    default:
      return brandingEnelion;
  }
};

const colors = {
  ...rawColors,

  // connector statuses
  charging: rawColors.blue,
  available: rawColors.green_light,
  reserved: rawColors.purple,
  unavailable: rawColors.grey_silver,
  offline: rawColors.black,
  faulted: rawColors.red_light,

  // other
  text: rawColors.black_light,
  success: rawColors.green,
  info: rawColors.grey,
  danger: rawColors.red,
  error: rawColors.red,
  warning: rawColors.yellow,
  disable: rawColors.grey,
  unknown: rawColors.yellow,

  ...(getBranding()),
};

export const labelsColors = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
];

export const avatarColors = [
  '#037BF2',
  '#125CA6',
  '#F4291B',
  '#ADBF15',
  '#95A60A',
  '#FF00C5',
  '#FFD819',
  '#B3128D',
  '#14CCC9',
  '#09B3AF',
  '#856bd9',
  '#4B378C',
  '#DFAE84',
  '#41A646',
  '#3E8C42',
  '#00BBD3',
  '#0E7987',
  '#DB167B',
  '#A19010',
  '#877807',
  '#FFB422',
  '#B37905',
  '#3BFFA0',
  '#8D06CC',
  '#7E0EB3',
  '#87A8B6',
  '#435D69',
  '#C2A3A8',
  '#827E21',
  '#6E6C47',
  '#F0CBA8',
  '#3F2096',
  '#7046E3',
  '#8DB090',
  '#229C2D',
];

export default colors;
