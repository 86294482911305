import { Dictionary } from '@/typesAndInterfaces/miscellaneous';


interface Action {
  type: string,
  httpGetArgs: Dictionary<string>,
}

export const SET_HTTP_GET_ARGS = 'API/SET_HTTP_GET_ARGS';

const initialState = {
  httpGetArgs: {},
};

export default (
  state: { httpGetArgs: Dictionary<string> } = initialState,
  action: Action,
) => {
  switch (action.type) {
    case SET_HTTP_GET_ARGS:
      return {
        ...state,
        httpGetArgs: action.httpGetArgs,
      };
    default:
      return state;
  }
};


export function setHttpGetArgs(httpGetArgs: Dictionary<string>) {
  return { type: SET_HTTP_GET_ARGS, httpGetArgs };
}
