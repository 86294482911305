import { SocketType } from '@/typesAndInterfaces/chargingPoint';
import { TENANT } from '@/constants/apiConstants';


export const maxInputValueLength = 255;
export const browserTitleSufix = process.env.BRANDING;

export const socketTypes: {[key: string]: SocketType} = {
  TYPE1: 'TYPE1',
  TYPE2: 'TYPE2',
  CCS: 'CCS',
  CHADEMO: 'CHADEMO',
};

export const tooltipDelay = 500;

const coordinates = [
  { // Katowice, Tauron
    lat: 50.268973,
    lng: 19.0082387,
  },
  { // Warszawa, Polenergia
    lat: 52.2267062,
    lng: 21.018808,
  },
];

export const defaultCoordinates = TENANT === 'POLENERGIA' ? coordinates[1] : coordinates[0];

export const requiredEnergyPotential = 301;
export const dashboardWidgetHeight = 450;
export const deleteAccountTimeLimit = 30;
export const deleteAccountDeadlineNearDays = 7;
