import { User } from '@/typesAndInterfaces/user';

const SET_USER = 'USER/SET_USER';
const CLEAR_USER = 'USER/CLEAR_USER';
const SET_EMAIL = 'USER/SET_EMAIL';
const SET_NAME = 'USER/SET_NAME';
const SET_PERMISSIONS = 'USER/SET_PERMISSIONS';
const SET_ROLES = 'USER/SET_ROLES';
const SET_IS_PROVIDER = 'USER/SET_IS_PROVIDER';
const SET_IS_OPERATOR = 'USER/SET_IS_OPERATOR';


interface IState extends User {
  confirmed: boolean,
  is_active: boolean,
  name: string,
  isProvider: boolean,
  isOperator: boolean,
}

interface IAction extends IState {
  type: string,
  userObject: IState,
}

const initialState: IState = {
  email: '',
  confirmed: false,
  first_name: '',
  last_name: '',
  is_active: false,
  phone_number: '',
  roles: [],
  permissions: [],
  username: '',
  uuid: '',
  name: '',
  isProvider: false,
  isOperator: false,
};

export default function user(
  state: IState = initialState,
  action: IAction,
) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.userObject,
      };
    case CLEAR_USER:
      return initialState;
    case SET_NAME:
      return {
        ...state,
        name: action.name,
      };
    case SET_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions,
      };
    case SET_ROLES:
      return {
        ...state,
        roles: action.roles,
      };
    case SET_IS_PROVIDER:
      return {
        ...state,
        isProvider: action.isProvider,
      };
    case SET_IS_OPERATOR:
      return {
        ...state,
        isOperator: action.isOperator,
      };
    default:
      return state;
  }
}


export function setUser(userObject: User) {
  return { type: SET_USER, userObject };
}

export function clearUser() {
  return { type: CLEAR_USER };
}

export function setPermissions(permissions: string[]) {
  return { type: SET_PERMISSIONS, permissions };
}

export function setRoles(roles: string[]) {
  return { type: SET_ROLES, roles };
}

export function setIsProvider(isProvider: boolean) {
  return { type: SET_IS_PROVIDER, isProvider };
}

export function setIsOperator(isOperator: boolean) {
  return { type: SET_IS_OPERATOR, isOperator };
}
