import { Label } from '@/typesAndInterfaces/label';


interface Action {
  type: string,
  labels: Label[]|null,
}

export const SET_SELECTED_ITEM_LABELS = 'SELECTED_ITEM_LABELS/SET_LABELS';

const initialState = {
  labels: [],
};

export default (
  state: { labels: Label[]|null } = initialState,
  action: Action,
) => {
  switch (action.type) {
    case SET_SELECTED_ITEM_LABELS:
      return {
        ...state,
        labels: action.labels,
      };
    default:
      return state;
  }
};

export function setLabels(labels: Label[]) {
  return { type: SET_SELECTED_ITEM_LABELS, labels };
}
