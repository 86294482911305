import React from 'react';
import classNames from 'classnames';
import Button, { ButtonProps } from '@material-ui/core/Button';
import styles from './GenericButton.scss';

/** ButtonProps: https://material-ui.com/api/button/ */
interface IProps extends Omit<ButtonProps, 'children' | 'variant' | 'size'> {
  className?: string,
  styling?: 'grey' | 'black' | 'salmon' | 'primary' | 'blackText' | 'white' | 'transparent',
  text: string,
  icon?: React.ReactNode,
  defaultWidth?: boolean,
  variant?: 'text' | 'outlined' | 'contained',
}

const GenericButton = ({
  className,
  text,
  icon,
  styling,
  defaultWidth,
  variant,
  ...otherProps
}: IProps) => (
  <Button
    variant={variant}
    className={classNames(
      className,
      styles.flatButton,
      styling,
      {
        [styles.defaultWidth]: defaultWidth,
      },
    )}
    {...otherProps}
  >
    {icon !== null && (
      <span
        className={styles.iconWrapper}
      >
        {icon}
      </span>
    )}
    {text}
  </Button>
);

GenericButton.defaultProps = {
  variant: 'outlined',
  className: '',
  styling: 'primary',
  icon: null,
};

export default GenericButton;
