import {
  OperatorGroup,
  OperatorGroupWithChildren,
} from '@/typesAndInterfaces/operatorGroup';
import { clone } from '@/utilities/miscellaneous';

interface State {
  operatorGroups: OperatorGroup[],
  operatorGroup: OperatorGroup | null,
  stationsQuantity: number | null,
}

interface Action extends State {
  type: string,
}

const SET_OPERATOR_GROUPS = 'OPERATOR_GROUPS/SET_OPERATOR_GROUPS';
const SET_SINGLE_OPERATOR_GROUP = 'OPERATOR_GROUPS/SET_SINGLE_OPERATOR_GROUPS';
const SET_STATIONS_QUANTITY = 'OPERATOR_GROUPS/SET_STATIONS_QUANTITY';

const initialState: State = {
  operatorGroups: [],
  operatorGroup: null,
  stationsQuantity: null,
};

const replaceOperatorGroup = (
  operatorGroups: OperatorGroupWithChildren[],
  group: OperatorGroup | null,
) => {
  operatorGroups.forEach((operatorGroup, index) => {
    if (operatorGroup.uuid === group?.uuid) {
      // eslint-disable-next-line no-param-reassign
      operatorGroups[index] = {
        ...operatorGroup,
        ...group,
      };
    } else if (operatorGroup.children?.length) {
      replaceOperatorGroup(operatorGroup.children, group);
    }
  });
};

export default (state: State = initialState, action: Action) => {
  const newGroups: OperatorGroupWithChildren[] = clone(state.operatorGroups);
  switch (action.type) {
    case SET_OPERATOR_GROUPS:
      return {
        ...state,
        operatorGroups: action.operatorGroups,
      };
    case SET_SINGLE_OPERATOR_GROUP:
      replaceOperatorGroup(newGroups, action.operatorGroup);
      return {
        ...state,
        operatorGroups: newGroups,
      };
    case SET_STATIONS_QUANTITY:
      return {
        ...state,
        stationsQuantity: action.stationsQuantity,
      };
    default:
      return state;
  }
};

export function setOperatorGroups(operatorGroups: OperatorGroup[]) {
  return { type: SET_OPERATOR_GROUPS, operatorGroups };
}

export function setSingleOperatorGroup(operatorGroup: OperatorGroup) {
  return { type: SET_SINGLE_OPERATOR_GROUP, operatorGroup };
}

export function setStationsQuantity(stationsQuantity: number | null) {
  return { type: SET_STATIONS_QUANTITY, stationsQuantity };
}
