import React from 'react';
import { Translation } from 'react-i18next';
import Page from '@/components/Page';

interface IProps {
  error: Error,
  pastDelay: boolean,
  isLoading: boolean,
  timedOut: boolean,
  retry: () => void,
}


export default function wrapLoadingIndicator(WrappedComponent: React.ComponentType) {
  return class LoadableWrapperForLoadingIndicator extends React.Component<IProps> {
    render() {
      // this props are passed by react-loadable library, that's why they are not verified
      const { error, pastDelay } = this.props;

      if (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        // @ts-ignore
        error.combineMessage = 'Look at the console.';

        return (
          <Translation ns="componentsShared">
            {t => (
              <Page
                metaData={{ title: t('componentsShared:wrapLoadingIndicator.errorPageTitle') }}
                error={process.env.NODE_ENV === 'development'
                  ? error
                  : Error(t('componentsShared:wrapLoadingIndicator.pleaseRefreshPage'))}
              />
            )}
          </Translation>
        );
      }
      if (pastDelay) {
        return <WrappedComponent />;
      }
      return null; // Avoiding Flash Of Loading Component
    }
  };
}
