import { Profile } from '@/typesAndInterfaces/profile';


interface Action {
  type: string,
  profiles: Profile[],
}

export const SET_PROFILES = 'PROFILES/SET_PROFILES';

const initialState = {
  profiles: [],
};

export default (
  state: { profiles: Profile[]|null } = initialState,
  action: Action,
) => {
  switch (action.type) {
    case SET_PROFILES:
      return {
        ...state,
        profiles: action.profiles,
      };
    default:
      return state;
  }
};


export function setProfiles(profiles: Profile[]) {
  return { type: SET_PROFILES, profiles };
}
