import { ChargingPoint } from '@/typesAndInterfaces/chargingPoint';


interface Action {
  type: string,
  chargingPoints: ChargingPoint[] | null,
  chosenChargingPoint: ChargingPoint | null,
}

const SET_CONNECTORS = 'CONNECTORS/SET_CONNECTORS';
const SET_CHOSEN_CHARGING_POINT = 'CONNECTORS/SET_CHOSEN_CHARGING_POINT';

const initialState = {
  chargingPoints: null,
  chosenChargingPoint: null,
};

export default (
  state: object = initialState,
  action: Action,
) => {
  switch (action.type) {
    case SET_CONNECTORS:
      return {
        ...state,
        chargingPoints: action.chargingPoints,
      };
    case SET_CHOSEN_CHARGING_POINT:
      return {
        ...state,
        chosenChargingPoint: action.chosenChargingPoint,
      };
    default:
      return state;
  }
};

export function setChargingPoints(chargingPoints: ChargingPoint[]) {
  return { type: SET_CONNECTORS, chargingPoints };
}

export function setChosenChargingPoint(chosenChargingPoint: ChargingPoint | null) {
  return { type: SET_CHOSEN_CHARGING_POINT, chosenChargingPoint };
}
