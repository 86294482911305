import React from 'react';
import Noty from 'noty';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import MomentUtils from '@date-io/moment';
import { BrowserRouter as Router } from 'react-router-dom';
import CssVariablesProvider from '@/components/CssVariablesProvider';
import MainRoots from '@/pages';
import { store } from '@/redux/store';
import i18n from '@/i18n';
import lightTheme from '@/constants/lightTheme';
import { notyConfig } from '@/constants/config';
/* eslint-disable import/no-unresolved */
import 'noty/src/noty.scss?raw';
import 'noty/src/themes/metroui.scss?raw';
import '@/styles/notyOvewrrides.scss?raw';
import '@/styles/global.scss?raw';
/* eslint-enable */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const sentryDsn = process.env.SENTRY_DSN;
const envName = process.env.ENV_NAME;

if (sentryDsn && envName) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: process.env.ENV_NAME,
    environment: process.env.ENV_NAME,
  });
}

Noty.overrideDefaults(notyConfig);

const App = () => (
  <Provider store={store}>
    <CssVariablesProvider>
      <I18nextProvider i18n={i18n}>
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          locale={i18n.language || 'en'}
        >
          <MuiThemeProvider theme={lightTheme}>
            <CssBaseline />
            <Router>
              <MainRoots />
            </Router>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </I18nextProvider>
    </CssVariablesProvider>
  </Provider>
);

export default App;
