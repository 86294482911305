import axios, {
  AxiosInstance, AxiosRequestConfig, AxiosResponse, Method,
} from 'axios';
// @ts-ignore
import createError from 'axios/lib/core/createError';
import fileDownload from 'js-file-download';
import { apiURL } from '@/constants/apiConstants';
import { Dictionary, FetchFromEnelionApiBody } from '@/typesAndInterfaces/miscellaneous';
import showNoty from '@/utilities/showNoty';
import { addGlobalFilters, parseGetArgs } from './helpers';
import { parseError } from './parseError';
import { FetchFromEnelionApiOptions } from './types';


const sessionHeaderKey = 'authorization';
const getAcceptHeader = (apiVersion: string) => `application/vnd.enelion.${apiVersion}`;

const enelionApiAxiosInstance = axios.create({
  baseURL: apiURL,
  timeout: 30 * 1000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    // eslint-disable-next-line quote-props
    'Accept': getAcceptHeader('v2'),
  },
});


const enelionApiAxiosInstanceV1 = axios.create({
  baseURL: apiURL,
  timeout: 30 * 1000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    // eslint-disable-next-line quote-props
    'Accept': getAcceptHeader('v1'),
  },
});

const axiosInstanceForCustomCalls = axios.create({
  timeout: 30 * 1000,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
  },
});

const getFileNameFromHeader = (response: AxiosResponse) => {
  const headerParts = response.headers['content-disposition']?.split(';');
  if (headerParts?.length >= 2) {
    return headerParts[1].split('filename=')[1];
  }
  return 'file';
};

const removeSpaces = (body: FetchFromEnelionApiBody | null) => {
  const result = body;
  if (!body) {
    return result;
  }
  for (const key in result) {
    if (Object.prototype.hasOwnProperty.call(result, key) && typeof result[key] === 'string') {
      result[key] = (result[key] as string).trim();
    }
  }
  return result;
};

async function fetchWithAxios(
  axiosInstance: AxiosInstance,
  options: AxiosRequestConfig,
  disableCatcher: boolean = false,
): Promise<AxiosResponse> {
  if (options.url === null || options.url === undefined) {
    throw Error('url is undefined or null');
  }
  try {
    const response = await axiosInstance(options);

    if (response?.headers['content-type']?.startsWith('text/html')) {
      throw createError('Server returned text/html', options, response.status, response.request, response);
    }

    return response;
  } catch (error) {
    error.combineMessage = parseError(error);
    if (error?.response?.status !== 401 && error?.response?.data?.message && !disableCatcher) {
      showNoty('error', JSON.stringify(error?.response?.data?.message));
    }
    throw error;
  }
}


// in original fetch was flag to omit credential. Maybe it should be added here too
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function fetchFromEnelionApi<T = any>(
  method: Method,
  url: string,
  bodyOrArgs: FetchFromEnelionApiBody | null = null,
  options: FetchFromEnelionApiOptions = {}
): Promise<T> {
  /*
   * Structure of bodyOrArgs in GET request:
   *   bodyOrArgs is an object
   *   key is a name of argument and value is a value of this argument
   *   So { getActive: true, aa: 'bb' } is transformed to 'http://[url]?getActive=true&aa=bb'
   *
   *  an exception is key 'filter', it use 'Filtration system'. http://enelion-dev-p3.herokuapp.com/slate/#filtration_system
   *  For example this object:
   *  {
   *    getActive: true,
   *    aa: 'bb'
   *    filter: [
   *      ['age', '>=', 24 ],
   *      ['model', 'eq', 'Vartica' ],
   *    ]
   *  }
   *
   *   is transformed to 'http://[url]?getActive=true&aa=bb&filter=age;ge;24&filter=model;eq;Vartica'
   */
  const {
    getFullResponse = false,
    ignoreGlobalFilters = false,
    apiVersion = 'v2',
    downloadFile = false,
    disableCatcher = false,
    timeout,
  } = options;


  let getArgs = bodyOrArgs || {};

  const sessionHeader = localStorage.getItem('sessionHeader');
  const axiosOptions: AxiosRequestConfig = {
    method,
    headers: sessionHeader ? { [sessionHeaderKey]: `Bearer ${sessionHeader}` } : {},
    timeout,
  };
  // required to test with local backend
  // axiosOptions.headers['X-tenant-name'] = 'polenergia';

  if (method.toLowerCase() !== 'get') {
    axiosOptions.url = url;
    axiosOptions.data = removeSpaces(bodyOrArgs);
  } else {
    if (url.includes('?')) {
      throw Error('Arguments for GET should be pass via "bodyOrArgs" arg of "fetchFromEnelionApi" function, not directly in url');
    }

    if (!ignoreGlobalFilters) {
      getArgs = addGlobalFilters(getArgs);
    }
    axiosOptions.url = url + parseGetArgs(getArgs);
  }
  if (downloadFile) {
    axiosOptions.responseType = 'blob';
  }
  const response: AxiosResponse = await fetchWithAxios(
    apiVersion === 'v1'
      ? enelionApiAxiosInstanceV1
      : enelionApiAxiosInstance,
    axiosOptions,
    disableCatcher,
  );

  const newSessionHeader = response?.headers[sessionHeaderKey];
  if (newSessionHeader) {
    localStorage.setItem('sessionHeader', newSessionHeader);
  }
  if (downloadFile) {
    fileDownload(response.data, getFileNameFromHeader(response));
  }
  return getFullResponse ? response : response?.data;
}

export async function fetchFromOtherSource(
  method: Method,
  url: string,
  body: Dictionary<string> | null = null,
  getFullResponse: boolean = false
) {
  const options: AxiosRequestConfig = {
    method,
    url,
  };

  if (method !== 'GET') {
    options.data = removeSpaces(body);
  }

  const response = await fetchWithAxios(axiosInstanceForCustomCalls, options);
  return getFullResponse ? response : response?.data;
}
