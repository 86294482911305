//  pages with // ^ have tests

const roots = {
  root: '/',
  app: '/app',
  company: '/app/company',
  users: '/app/users',
  common: '/app/common',

  user: {
    root: '/app/user',
    profiles: '/app/user/profiles',
    reports: '/app/user/reports',
  },
  superAdmin: {
    root: '/app/super-admin',
    providersManagement: '/app/super-admin/providers-management',
    permissions: '/app/super-admin/permissions',
  },
  operator: {
    root: '/app/operator',
    collaborators: '/app/operator/collaborators',
    operators: '/app/operator/operators',
    chargers: '/app/operator/chargers',
    transactions: '/app/operator/transactions',
    smartCharging: '/app/operator/smart-charging',
    labels: '/app/operator/labels',
    issues: '/app/operator/issues',
    locations: '/app/operator/locations',
  },
  provider: {
    root: '/app/provider',
    clients: '/app/provider/clients',
    collaborators: '/app/provider/collaborators',
    transactions: '/app/provider/transactions',
    rfidCards: '/app/provider/rfid-cards',
    tariffs: '/app/provider/tariffs',
    labels: '/app/provider/labels',
    policies: '/app/provider/policies',
    profiles: '/app/provider/profiles',
    reports: '/app/provider/reports',
    payments: '/app/provider/payments',
    platformIssues: '/app/provider/platform-issues',
    startTransaction: '/app/provider/start-transaction',
  },
};

export default {
  app: {
    root: roots.app,
    common: {
      root: roots.common,
      dashboard: `${roots.common}/dashboard`, // ^
      myProfile: `${roots.common}/my-profile`, // ^
      preferences: `${roots.common}/preferences`, // ^
      deletionRequests: `${roots.common}/deletionRequests`, // ^
    },
    user: {
      root: roots.user.root,
      profiles: {
        root: `${roots.user.profiles}/profiles`, // ^
        profileDetails: (uuid) => `${roots.user.profiles}/${uuid}`,
      },
      reports: {
        root: roots.user.reports,
        exportReport: `${roots.user.reports}/export/`,
      },
    },
    superAdmin: {
      root: roots.superAdmin.root,
      permissions: {
        root: roots.superAdmin.permissions,
        roles: `${roots.superAdmin.permissions}/roles`, // ^
      },
    },
    operator: {
      root: roots.operator.root,
      collaborators: {
        root: roots.operator.collaborators,
        collaboratorList: `${roots.operator.collaborators}/collaborator-list`, // ^
        createCollaborator: `${roots.operator.collaborators}/create-collaborator`, // ^
        collaboratorProfile: (uuid) => `${roots.operator.collaborators}/collaborator-profile/${uuid}`, // ^
      },
      operators: {
        root: roots.operator.operators,
        groupStructure: `${roots.operator.operators}/group-structure`, // ^
        createOperator: `${roots.operator.operators}/create-operator`, // ^
        invitation: (uuid) => `${roots.operator.operators}/${uuid}/invitation`, // ^
      },
      chargers: {
        root: roots.operator.chargers,
        networks: `${roots.operator.chargers}/networks`, // ^
        connectors: `${roots.operator.chargers}/connectors`, // ^
        createNetwork: `${roots.operator.chargers}/create-network`, // ^
        location: `${roots.operator.locations}/add-location`, // ^

        connectorsStatus: (status) => `${roots.operator.chargers}/connectors${status}`, // ^
        actionsHistoryConnectors: (uuid) => `${roots.operator.chargers}/actions-history-connectors/${uuid}`, // ^
        actionsHistoryNetworks: (uuid) => `${roots.operator.chargers}/actions-history-networks/${uuid}`, // ^
        connectorDetails: (uuid) => `${roots.operator.chargers}/connector-details/${uuid}`, // ^
        networkDetails: (uuid) => `${roots.operator.chargers}/network-details/${uuid}`, // ^
      },
      transactions: {
        root: roots.operator.transactions,
        transactionsList: `${roots.operator.transactions}/transactions-list`, // ^
        operatorDetails: (uuid) => `${roots.operator.transactions}/operator-transaction-details/${uuid}`, // ^
      },
      smartCharging: {
        root: roots.operator.smartCharging,
        chargingProfilesList: `${roots.operator.smartCharging}/charging-profiles-list`, // ^
        createChargingProfile: `${roots.operator.smartCharging}/create-charging-profile`, // ^
      },
      labels: {
        root: roots.operator.labels,
        labelsList: `${roots.operator.labels}/labels-list`, // ^
        labelsPairing: `${roots.operator.labels}/label-pairing`, // ^
      },
      issues: {
        root: roots.operator.issues, // ^
        issuesList: `${roots.operator.issues}/issues-list`, // ^
        addIssue: `${roots.operator.issues}/add-issue`, // ^
      },
      locations: {
        root: roots.operator.locations, // ^
        locationsList: `${roots.operator.locations}/locations-list`, // ^
        addLocation: `${roots.operator.locations}/add-location`, // ^
      },
    },
    provider: {
      root: roots.provider.root,
      clients: {
        root: roots.provider.clients,
        clientsList: `${roots.provider.clients}/clients-list`, // ^
        clientProfile: (uuid) => `${roots.provider.clients}/client-profile/${uuid}`, // ^
        assignOperatorRoles: (uuid) => `${roots.provider.clients}/client-profile/${uuid}/operator-roles`,
        assignProviderRoles: (uuid) => `${roots.provider.clients}/client-profile/${uuid}/provider-roles`,
      },
      collaborators: {
        root: roots.provider.collaborators,
        collaboratorList: `${roots.provider.collaborators}/collaborator-list`, // ^
        createCollaborator: `${roots.provider.collaborators}/create-collaborator`, // ^
      },
      transactions: {
        root: roots.provider.transactions,
        transactionsList: `${roots.provider.transactions}/transactions-list`, // ^
        providerDetails: (uuid) => `${roots.provider.transactions}/provider-transaction-details/${uuid}`, // ^
      },
      rfidCards: {
        root: roots.provider.rfidCards,
        assignRfidToUser: `${roots.provider.rfidCards}/assign-rfid-to-user`, // ^
        rfidsList: `${roots.provider.rfidCards}/rfids-list`, // ^
        editRfid: (uuid) => `${roots.provider.rfidCards}/edit-rfid/${uuid}`,
        ordersList: `${roots.provider.rfidCards}/orders`,
        productsList: `${roots.provider.rfidCards}/products`,
        addProduct: `${roots.provider.rfidCards}/add-product`,
      },
      tariffs: {
        root: roots.provider.tariffs,
        tariffsList: `${roots.provider.tariffs}/tariffs-list`, // ^
        tariffAssignment: `${roots.provider.tariffs}/tariff-assignment`, // ^
        createTariff: `${roots.provider.tariffs}/create-tariff`, // ^
        createSubscription: `${roots.provider.tariffs}/create-subscription`,
        subscriptionsList: `${roots.provider.tariffs}/subscriptions-list`, // ^
      },
      labels: {
        root: roots.provider.labels,
        labelsList: `${roots.provider.labels}/labels-list`, // ^
        labelsPairing: `${roots.provider.labels}/label-pairing`, // ^
      },
      policies: {
        root: roots.provider.policies,
        policiesList: `${roots.provider.policies}/policies-list`, // ^
        addPolicy: `${roots.provider.policies}/add-policy`, // ^
      },
      companies: {
        root: roots.provider.profiles,
        addProfile: `${roots.provider.profiles}/add-profile`, // ^
        requestsList: `${roots.provider.profiles}/requests-list`, // ^
        profilesList: `${roots.provider.profiles}/profiles-list`, // ^
        invite: `${roots.provider.profiles}/invite`,
        profileDetails: (uuid) => `${roots.provider.profiles}/profile-details/${uuid}`, // ^
      },
      reports: {
        root: roots.provider.reports,
        exportReport: `${roots.provider.reports}/export/`,
      },
      preferences: {
        root: roots.provider.preferences, // ^
      },
      payments: {
        root: roots.provider.payments,
        invoicesList: `${roots.provider.payments}/invoices-list`, // ^
        addCorrection: (uuid) => `${roots.provider.payments}/add-correction/${uuid}`, // ^
        editCorrection: (uuid) => `${roots.provider.payments}/edit-correction/${uuid}`, // ^
        xpertis: `${roots.provider.payments}/xpertis`, // ^
      },
      platformIssues: {
        root: roots.provider.platformIssues,
        issuesList: `${roots.provider.platformIssues}/issues-list`, // ^
      },
      startTransaction: {
        root: roots.provider.startTransaction,
        chargingPoint: (uuid) => `${roots.provider.startTransaction}/charging-point/${uuid}`, // ^
        station: (uuid) => `${roots.provider.startTransaction}/station/${uuid}`, // ^
      },
    },
  },
  root: roots.root,
  forgotPassword: '/forgot-password', // ^
  invitation: '/invitations', // ^
  login: '/login', // ^
  logout: '/logout', // ^
  passwordReset: (confirm) => `/password-reset/${confirm}`, // ^
};
