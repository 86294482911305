/* eslint-disable quote-props */

export const languages = {
  pl: 'Polski',
  en: 'English',
  // sv: 'Swedish',
  // no: 'Norwegian',
  // fr: 'Français',
  // bg: 'Bulgarian',
  // es: 'Spanish',
  // cs: 'Czech',
  // da: 'Danish',
  // de: 'Deutsch',
  // et: 'Estonian',
  // el: 'Greek',
  // ga: 'Gaeilge',
  // hr: 'Croatian',
  // it: 'Italian',
  // lv: 'Latvian',
  // lt: 'Lithuanian',
  // hu: 'Hungarian',
  // mt: 'Maltese',
  // nl: 'Dutch',
  // pt: 'Portuguese',
  // ro: 'Romanian',
  // sk: 'Slovak',
  // sl: 'Slovenian',
  // fi: 'Finnish',
};

export const profileLanguages = {
  pl: 'Polski',
  en: 'English',
  // sv: 'Swedish',
  // no: 'Norwegian',
  fr: 'Français',
  // bg: 'Bulgarian',
  // es: 'Spanish',
  // cs: 'Czech',
  // da: 'Danish',
  // de: 'Deutsch',
  // et: 'Estonian',
  // el: 'Greek',
  // ga: 'Gaeilge',
  // hr: 'Croatian',
  // it: 'Italian',
  // lv: 'Latvian',
  // lt: 'Lithuanian',
  // hu: 'Hungarian',
  // mt: 'Maltese',
  // nl: 'Dutch',
  // pt: 'Portuguese',
  // ro: 'Romanian',
  // sk: 'Slovak',
  // sl: 'Slovenian',
  // fi: 'Finnish',
};


// example: 20.06.2020
export const dateFormat = 'DD.MM.YYYY';
// example: 22:56
export const timeFormat = 'HH:mm';
export const timeFormatWithSeconds = 'HH:mm:ss';
// example: 20.06.2020 22:56
export const dateTimeFormat = `${dateFormat} ${timeFormat}`;

export const dateTimeFormatUTC = 'YYYY-MM-DDTHH:mm:ss';

export const fallbackTimezone = 'Europe/London';

// timezones should use the IANA standard

//  'America/Chicago': 'AmericaChicago',
//   'Europe/Warsaw': 'EuropeWarsaw',
export const timezones = {
  'Africa/Abidjan': 'AfricaAbidjan',
  'Africa/Accra': 'AfricaAccra',
  'Africa/Addis_Ababa': 'AfricaAddis_Ababa',
  'Africa/Algiers': 'AfricaAlgiers',
  'Africa/Asmara': 'AfricaAsmara',
  'Africa/Asmera': 'AfricaAsmera',
  'Africa/Bamako': 'AfricaBamako',
  'Africa/Bangui': 'AfricaBangui',
  'Africa/Banjul': 'AfricaBanjul',
  'Africa/Bissau': 'AfricaBissau',
  'Africa/Blantyre': 'AfricaBlantyre',
  'Africa/Brazzaville': 'AfricaBrazzaville',
  'Africa/Bujumbura': 'AfricaBujumbura',
  'Africa/Cairo': 'AfricaCairo',
  'Africa/Casablanca': 'AfricaCasablanca',
  'Africa/Ceuta': 'AfricaCeuta',
  'Africa/Conakry': 'AfricaConakry',
  'Africa/Dakar': 'AfricaDakar',
  'Africa/Dar_es_Salaam': 'AfricaDar_es_Salaam',
  'Africa/Djibouti': 'AfricaDjibouti',
  'Africa/Douala': 'AfricaDouala',
  'Africa/El_Aaiun': 'AfricaEl_Aaiun',
  'Africa/Freetown': 'AfricaFreetown',
  'Africa/Gaborone': 'AfricaGaborone',
  'Africa/Harare': 'AfricaHarare',
  'Africa/Johannesburg': 'AfricaJohannesburg',
  'Africa/Juba': 'AfricaJuba',
  'Africa/Kampala': 'AfricaKampala',
  'Africa/Khartoum': 'AfricaKhartoum',
  'Africa/Kigali': 'AfricaKigali',
  'Africa/Kinshasa': 'AfricaKinshasa',
  'Africa/Lagos': 'AfricaLagos',
  'Africa/Libreville': 'AfricaLibreville',
  'Africa/Lome': 'AfricaLome',
  'Africa/Luanda': 'AfricaLuanda',
  'Africa/Lubumbashi': 'AfricaLubumbashi',
  'Africa/Lusaka': 'AfricaLusaka',
  'Africa/Malabo': 'AfricaMalabo',
  'Africa/Maputo': 'AfricaMaputo',
  'Africa/Maseru': 'AfricaMaseru',
  'Africa/Mbabane': 'AfricaMbabane',
  'Africa/Mogadishu': 'AfricaMogadishu',
  'Africa/Monrovia': 'AfricaMonrovia',
  'Africa/Nairobi': 'AfricaNairobi',
  'Africa/Ndjamena': 'AfricaNdjamena',
  'Africa/Niamey': 'AfricaNiamey',
  'Africa/Nouakchott': 'AfricaNouakchott',
  'Africa/Ouagadougou': 'AfricaOuagadougou',
  'Africa/Porto-Novo': 'AfricaPorto-Novo',
  'Africa/Sao_Tome': 'AfricaSao_Tome',
  'Africa/Timbuktu': 'AfricaTimbuktu',
  'Africa/Tripoli': 'AfricaTripoli',
  'Africa/Tunis': 'AfricaTunis',
  'Africa/Windhoek': 'AfricaWindhoek',
  'America/Adak': 'AmericaAdak',
  'America/Anchorage': 'AmericaAnchorage',
  'America/Anguilla': 'AmericaAnguilla',
  'America/Antigua': 'AmericaAntigua',
  'America/Araguaina': 'AmericaAraguaina',
  'America/Argentina/Buenos_Aires': 'AmericaArgentinaBuenos_Aires',
  'America/Argentina/Catamarca': 'AmericaArgentinaCatamarca',
  'America/Argentina/ComodRivadavia': 'AmericaArgentinaComodRivadavia',
  'America/Argentina/Cordoba': 'AmericaArgentinaCordoba',
  'America/Argentina/Jujuy': 'AmericaArgentinaJujuy',
  'America/Argentina/La_Rioja': 'AmericaArgentinaLa_Rioja',
  'America/Argentina/Mendoza': 'AmericaArgentinaMendoza',
  'America/Argentina/Rio_Gallegos': 'AmericaArgentinaRio_Gallegos',
  'America/Argentina/Salta': 'AmericaArgentinaSalta',
  'America/Argentina/San_Juan': 'AmericaArgentinaSan_Juan',
  'America/Argentina/San_Luis': 'AmericaArgentinaSan_Luis',
  'America/Argentina/Tucuman': 'AmericaArgentinaTucuman',
  'America/Argentina/Ushuaia': 'AmericaArgentinaUshuaia',
  'America/Aruba': 'AmericaAruba',
  'America/Asuncion': 'AmericaAsuncion',
  'America/Atikokan': 'AmericaAtikokan',
  'America/Atka': 'AmericaAtka',
  'America/Bahia': 'AmericaBahia',
  'America/Bahia_Banderas': 'AmericaBahia_Banderas',
  'America/Barbados': 'AmericaBarbados',
  'America/Belem': 'AmericaBelem',
  'America/Belize': 'AmericaBelize',
  'America/Blanc-Sablon': 'AmericaBlanc-Sablon',
  'America/Boa_Vista': 'AmericaBoa_Vista',
  'America/Bogota': 'AmericaBogota',
  'America/Boise': 'AmericaBoise',
  'America/Buenos_Aires': 'AmericaBuenos_Aires',
  'America/Cambridge_Bay': 'AmericaCambridge_Bay',
  'America/Campo_Grande': 'AmericaCampo_Grande',
  'America/Cancun': 'AmericaCancun',
  'America/Caracas': 'AmericaCaracas',
  'America/Catamarca': 'AmericaCatamarca',
  'America/Cayenne': 'AmericaCayenne',
  'America/Cayman': 'AmericaCayman',
  'America/Chicago': 'AmericaChicago',
  'America/Chihuahua': 'AmericaChihuahua',
  'America/Coral_Harbour': 'AmericaCoral_Harbour',
  'America/Cordoba': 'AmericaCordoba',
  'America/Costa_Rica': 'AmericaCosta_Rica',
  'America/Creston': 'AmericaCreston',
  'America/Cuiaba': 'AmericaCuiaba',
  'America/Curacao': 'AmericaCuracao',
  'America/Danmarkshavn': 'AmericaDanmarkshavn',
  'America/Dawson': 'AmericaDawson',
  'America/Dawson_Creek': 'AmericaDawson_Creek',
  'America/Denver': 'AmericaDenver',
  'America/Detroit': 'AmericaDetroit',
  'America/Dominica': 'AmericaDominica',
  'America/Edmonton': 'AmericaEdmonton',
  'America/Eirunepe': 'AmericaEirunepe',
  'America/El_Salvador': 'AmericaEl_Salvador',
  'America/Ensenada': 'AmericaEnsenada',
  'America/Fort_Nelson': 'AmericaFort_Nelson',
  'America/Fort_Wayne': 'AmericaFort_Wayne',
  'America/Fortaleza': 'AmericaFortaleza',
  'America/Glace_Bay': 'AmericaGlace_Bay',
  'America/Godthab': 'AmericaGodthab',
  'America/Goose_Bay': 'AmericaGoose_Bay',
  'America/Grand_Turk': 'AmericaGrand_Turk',
  'America/Grenada': 'AmericaGrenada',
  'America/Guadeloupe': 'AmericaGuadeloupe',
  'America/Guatemala': 'AmericaGuatemala',
  'America/Guayaquil': 'AmericaGuayaquil',
  'America/Guyana': 'AmericaGuyana',
  'America/Halifax': 'AmericaHalifax',
  'America/Havana': 'AmericaHavana',
  'America/Hermosillo': 'AmericaHermosillo',
  'America/Indiana/Indianapolis': 'AmericaIndianaIndianapolis',
  'America/Indiana/Knox': 'AmericaIndianaKnox',
  'America/Indiana/Marengo': 'AmericaIndianaMarengo',
  'America/Indiana/Petersburg': 'AmericaIndianaPetersburg',
  'America/Indiana/Tell_City': 'AmericaIndianaTell_City',
  'America/Indiana/Vevay': 'AmericaIndianaVevay',
  'America/Indiana/Vincennes': 'AmericaIndianaVincennes',
  'America/Indiana/Winamac': 'AmericaIndianaWinamac',
  'America/Indianapolis': 'AmericaIndianapolis',
  'America/Inuvik': 'AmericaInuvik',
  'America/Iqaluit': 'AmericaIqaluit',
  'America/Jamaica': 'AmericaJamaica',
  'America/Jujuy': 'AmericaJujuy',
  'America/Juneau': 'AmericaJuneau',
  'America/Kentucky/Louisville': 'AmericaKentuckyLouisville',
  'America/Kentucky/Monticello': 'AmericaKentuckyMonticello',
  'America/Knox_IN': 'AmericaKnox_IN',
  'America/Kralendijk': 'AmericaKralendijk',
  'America/La_Paz': 'AmericaLa_Paz',
  'America/Lima': 'AmericaLima',
  'America/Los_Angeles': 'AmericaLos_Angeles',
  'America/Louisville': 'AmericaLouisville',
  'America/Lower_Princes': 'AmericaLower_Princes',
  'America/Maceio': 'AmericaMaceio',
  'America/Managua': 'AmericaManagua',
  'America/Manaus': 'AmericaManaus',
  'America/Marigot': 'AmericaMarigot',
  'America/Martinique': 'AmericaMartinique',
  'America/Matamoros': 'AmericaMatamoros',
  'America/Mazatlan': 'AmericaMazatlan',
  'America/Mendoza': 'AmericaMendoza',
  'America/Menominee': 'AmericaMenominee',
  'America/Merida': 'AmericaMerida',
  'America/Metlakatla': 'AmericaMetlakatla',
  'America/Mexico_City': 'AmericaMexico_City',
  'America/Miquelon': 'AmericaMiquelon',
  'America/Moncton': 'AmericaMoncton',
  'America/Monterrey': 'AmericaMonterrey',
  'America/Montevideo': 'AmericaMontevideo',
  'America/Montreal': 'AmericaMontreal',
  'America/Montserrat': 'AmericaMontserrat',
  'America/Nassau': 'AmericaNassau',
  'America/New_York': 'AmericaNew_York',
  'America/Nipigon': 'AmericaNipigon',
  'America/Nome': 'AmericaNome',
  'America/Noronha': 'AmericaNoronha',
  'America/North_Dakota/Beulah': 'AmericaNorth_DakotaBeulah',
  'America/North_Dakota/Center': 'AmericaNorth_DakotaCenter',
  'America/North_Dakota/New_Salem': 'AmericaNorth_DakotaNew_Salem',
  'America/Ojinaga': 'AmericaOjinaga',
  'America/Panama': 'AmericaPanama',
  'America/Pangnirtung': 'AmericaPangnirtung',
  'America/Paramaribo': 'AmericaParamaribo',
  'America/Phoenix': 'AmericaPhoenix',
  'America/Port-au-Prince': 'AmericaPort-au-Prince',
  'America/Port_of_Spain': 'AmericaPort_of_Spain',
  'America/Porto_Acre': 'AmericaPorto_Acre',
  'America/Porto_Velho': 'AmericaPorto_Velho',
  'America/Puerto_Rico': 'AmericaPuerto_Rico',
  'America/Punta_Arenas': 'AmericaPunta_Arenas',
  'America/Rainy_River': 'AmericaRainy_River',
  'America/Rankin_Inlet': 'AmericaRankin_Inlet',
  'America/Recife': 'AmericaRecife',
  'America/Regina': 'AmericaRegina',
  'America/Resolute': 'AmericaResolute',
  'America/Rio_Branco': 'AmericaRio_Branco',
  'America/Rosario': 'AmericaRosario',
  'America/Santa_Isabel': 'AmericaSanta_Isabel',
  'America/Santarem': 'AmericaSantarem',
  'America/Santiago': 'AmericaSantiago',
  'America/Santo_Domingo': 'AmericaSanto_Domingo',
  'America/Sao_Paulo': 'AmericaSao_Paulo',
  'America/Scoresbysund': 'AmericaScoresbysund',
  'America/Shiprock': 'AmericaShiprock',
  'America/Sitka': 'AmericaSitka',
  'America/St_Barthelemy': 'AmericaSt_Barthelemy',
  'America/St_Johns': 'AmericaSt_Johns',
  'America/St_Kitts': 'AmericaSt_Kitts',
  'America/St_Lucia': 'AmericaSt_Lucia',
  'America/St_Thomas': 'AmericaSt_Thomas',
  'America/St_Vincent': 'AmericaSt_Vincent',
  'America/Swift_Current': 'AmericaSwift_Current',
  'America/Tegucigalpa': 'AmericaTegucigalpa',
  'America/Thule': 'AmericaThule',
  'America/Thunder_Bay': 'AmericaThunder_Bay',
  'America/Tijuana': 'AmericaTijuana',
  'America/Toronto': 'AmericaToronto',
  'America/Tortola': 'AmericaTortola',
  'America/Vancouver': 'AmericaVancouver',
  'America/Virgin': 'AmericaVirgin',
  'America/Whitehorse': 'AmericaWhitehorse',
  'America/Winnipeg': 'AmericaWinnipeg',
  'America/Yakutat': 'AmericaYakutat',
  'America/Yellowknife': 'AmericaYellowknife',
  'Antarctica/Casey': 'AntarcticaCasey',
  'Antarctica/Davis': 'AntarcticaDavis',
  'Antarctica/DumontDUrville': 'AntarcticaDumontDUrville',
  'Antarctica/Macquarie': 'AntarcticaMacquarie',
  'Antarctica/Mawson': 'AntarcticaMawson',
  'Antarctica/McMurdo': 'AntarcticaMcMurdo',
  'Antarctica/Palmer': 'AntarcticaPalmer',
  'Antarctica/Rothera': 'AntarcticaRothera',
  'Antarctica/South_Pole': 'AntarcticaSouth_Pole',
  'Antarctica/Syowa': 'AntarcticaSyowa',
  'Antarctica/Troll': 'AntarcticaTroll',
  'Antarctica/Vostok': 'AntarcticaVostok',
  'Arctic/Longyearbyen': 'ArcticLongyearbyen',
  'Asia/Aden': 'AsiaAden',
  'Asia/Almaty': 'AsiaAlmaty',
  'Asia/Amman': 'AsiaAmman',
  'Asia/Anadyr': 'AsiaAnadyr',
  'Asia/Aqtau': 'AsiaAqtau',
  'Asia/Aqtobe': 'AsiaAqtobe',
  'Asia/Ashgabat': 'AsiaAshgabat',
  'Asia/Ashkhabad': 'AsiaAshkhabad',
  'Asia/Atyrau': 'AsiaAtyrau',
  'Asia/Baghdad': 'AsiaBaghdad',
  'Asia/Bahrain': 'AsiaBahrain',
  'Asia/Baku': 'AsiaBaku',
  'Asia/Bangkok': 'AsiaBangkok',
  'Asia/Barnaul': 'AsiaBarnaul',
  'Asia/Beirut': 'AsiaBeirut',
  'Asia/Bishkek': 'AsiaBishkek',
  'Asia/Brunei': 'AsiaBrunei',
  'Asia/Calcutta': 'AsiaCalcutta',
  'Asia/Chita': 'AsiaChita',
  'Asia/Choibalsan': 'AsiaChoibalsan',
  'Asia/Chongqing': 'AsiaChongqing',
  'Asia/Chungking': 'AsiaChungking',
  'Asia/Colombo': 'AsiaColombo',
  'Asia/Dacca': 'AsiaDacca',
  'Asia/Damascus': 'AsiaDamascus',
  'Asia/Dhaka': 'AsiaDhaka',
  'Asia/Dili': 'AsiaDili',
  'Asia/Dubai': 'AsiaDubai',
  'Asia/Dushanbe': 'AsiaDushanbe',
  'Asia/Famagusta': 'AsiaFamagusta',
  'Asia/Gaza': 'AsiaGaza',
  'Asia/Harbin': 'AsiaHarbin',
  'Asia/Hebron': 'AsiaHebron',
  'Asia/Ho_Chi_Minh': 'AsiaHo_Chi_Minh',
  'Asia/Hong_Kong': 'AsiaHong_Kong',
  'Asia/Hovd': 'AsiaHovd',
  'Asia/Irkutsk': 'AsiaIrkutsk',
  'Asia/Istanbul': 'AsiaIstanbul',
  'Asia/Jakarta': 'AsiaJakarta',
  'Asia/Jayapura': 'AsiaJayapura',
  'Asia/Jerusalem': 'AsiaJerusalem',
  'Asia/Kabul': 'AsiaKabul',
  'Asia/Kamchatka': 'AsiaKamchatka',
  'Asia/Karachi': 'AsiaKarachi',
  'Asia/Kashgar': 'AsiaKashgar',
  'Asia/Kathmandu': 'AsiaKathmandu',
  'Asia/Katmandu': 'AsiaKatmandu',
  'Asia/Khandyga': 'AsiaKhandyga',
  'Asia/Kolkata': 'AsiaKolkata',
  'Asia/Krasnoyarsk': 'AsiaKrasnoyarsk',
  'Asia/Kuala_Lumpur': 'AsiaKuala_Lumpur',
  'Asia/Kuching': 'AsiaKuching',
  'Asia/Kuwait': 'AsiaKuwait',
  'Asia/Macao': 'AsiaMacao',
  'Asia/Macau': 'AsiaMacau',
  'Asia/Magadan': 'AsiaMagadan',
  'Asia/Makassar': 'AsiaMakassar',
  'Asia/Manila': 'AsiaManila',
  'Asia/Muscat': 'AsiaMuscat',
  'Asia/Nicosia': 'AsiaNicosia',
  'Asia/Novokuznetsk': 'AsiaNovokuznetsk',
  'Asia/Novosibirsk': 'AsiaNovosibirsk',
  'Asia/Omsk': 'AsiaOmsk',
  'Asia/Oral': 'AsiaOral',
  'Asia/Phnom_Penh': 'AsiaPhnom_Penh',
  'Asia/Pontianak': 'AsiaPontianak',
  'Asia/Pyongyang': 'AsiaPyongyang',
  'Asia/Qatar': 'AsiaQatar',
  'Asia/Qyzylorda': 'AsiaQyzylorda',
  'Asia/Rangoon': 'AsiaRangoon',
  'Asia/Riyadh': 'AsiaRiyadh',
  'Asia/Saigon': 'AsiaSaigon',
  'Asia/Sakhalin': 'AsiaSakhalin',
  'Asia/Samarkand': 'AsiaSamarkand',
  'Asia/Seoul': 'AsiaSeoul',
  'Asia/Shanghai': 'AsiaShanghai',
  'Asia/Singapore': 'AsiaSingapore',
  'Asia/Srednekolymsk': 'AsiaSrednekolymsk',
  'Asia/Taipei': 'AsiaTaipei',
  'Asia/Tashkent': 'AsiaTashkent',
  'Asia/Tbilisi': 'AsiaTbilisi',
  'Asia/Tehran': 'AsiaTehran',
  'Asia/Tel_Aviv': 'AsiaTel_Aviv',
  'Asia/Thimbu': 'AsiaThimbu',
  'Asia/Thimphu': 'AsiaThimphu',
  'Asia/Tokyo': 'AsiaTokyo',
  'Asia/Tomsk': 'AsiaTomsk',
  'Asia/Ujung_Pandang': 'AsiaUjung_Pandang',
  'Asia/Ulaanbaatar': 'AsiaUlaanbaatar',
  'Asia/Ulan_Bator': 'AsiaUlan_Bator',
  'Asia/Urumqi': 'AsiaUrumqi',
  'Asia/Ust-Nera': 'AsiaUst-Nera',
  'Asia/Vientiane': 'AsiaVientiane',
  'Asia/Vladivostok': 'AsiaVladivostok',
  'Asia/Yakutsk': 'AsiaYakutsk',
  'Asia/Yangon': 'AsiaYangon',
  'Asia/Yekaterinburg': 'AsiaYekaterinburg',
  'Asia/Yerevan': 'AsiaYerevan',
  'Atlantic/Azores': 'AtlanticAzores',
  'Atlantic/Bermuda': 'AtlanticBermuda',
  'Atlantic/Canary': 'AtlanticCanary',
  'Atlantic/Cape_Verde': 'AtlanticCape_Verde',
  'Atlantic/Faeroe': 'AtlanticFaeroe',
  'Atlantic/Faroe': 'AtlanticFaroe',
  'Atlantic/Jan_Mayen': 'AtlanticJan_Mayen',
  'Atlantic/Madeira': 'AtlanticMadeira',
  'Atlantic/Reykjavik': 'AtlanticReykjavik',
  'Atlantic/South_Georgia': 'AtlanticSouth_Georgia',
  'Atlantic/St_Helena': 'AtlanticSt_Helena',
  'Atlantic/Stanley': 'AtlanticStanley',
  'Australia/ACT': 'AustraliaACT',
  'Australia/Adelaide': 'AustraliaAdelaide',
  'Australia/Brisbane': 'AustraliaBrisbane',
  'Australia/Broken_Hill': 'AustraliaBroken_Hill',
  'Australia/Canberra': 'AustraliaCanberra',
  'Australia/Currie': 'AustraliaCurrie',
  'Australia/Darwin': 'AustraliaDarwin',
  'Australia/Eucla': 'AustraliaEucla',
  'Australia/Hobart': 'AustraliaHobart',
  'Australia/LHI': 'AustraliaLHI',
  'Australia/Lindeman': 'AustraliaLindeman',
  'Australia/Lord_Howe': 'AustraliaLord_Howe',
  'Australia/Melbourne': 'AustraliaMelbourne',
  'Australia/NSW': 'AustraliaNSW',
  'Australia/North': 'AustraliaNorth',
  'Australia/Perth': 'AustraliaPerth',
  'Australia/Queensland': 'AustraliaQueensland',
  'Australia/South': 'AustraliaSouth',
  'Australia/Sydney': 'AustraliaSydney',
  'Australia/Tasmania': 'AustraliaTasmania',
  'Australia/Victoria': 'AustraliaVictoria',
  'Australia/West': 'AustraliaWest',
  'Australia/Yancowinna': 'AustraliaYancowinna',
  'Brazil/Acre': 'BrazilAcre',
  'Brazil/DeNoronha': 'BrazilDeNoronha',
  'Brazil/East': 'BrazilEast',
  'Brazil/West': 'BrazilWest',
  'Canada/Atlantic': 'CanadaAtlantic',
  'Canada/Central': 'CanadaCentral',
  'Canada/Eastern': 'CanadaEastern',
  'Canada/Mountain': 'CanadaMountain',
  'Canada/Newfoundland': 'CanadaNewfoundland',
  'Canada/Pacific': 'CanadaPacific',
  'Canada/Saskatchewan': 'CanadaSaskatchewan',
  'Canada/Yukon': 'CanadaYukon',
  'Chile/Continental': 'ChileContinental',
  'Chile/EasterIsland': 'ChileEasterIsland',
  'Cuba': 'Cuba',
  'Egypt': 'Egypt',
  'Eire': 'Eire',
  'Europe/Amsterdam': 'EuropeAmsterdam',
  'Europe/Andorra': 'EuropeAndorra',
  'Europe/Astrakhan': 'EuropeAstrakhan',
  'Europe/Athens': 'EuropeAthens',
  'Europe/Belfast': 'EuropeBelfast',
  'Europe/Belgrade': 'EuropeBelgrade',
  'Europe/Berlin': 'EuropeBerlin',
  'Europe/Bratislava': 'EuropeBratislava',
  'Europe/Brussels': 'EuropeBrussels',
  'Europe/Bucharest': 'EuropeBucharest',
  'Europe/Budapest': 'EuropeBudapest',
  'Europe/Busingen': 'EuropeBusingen',
  'Europe/Chisinau': 'EuropeChisinau',
  'Europe/Copenhagen': 'EuropeCopenhagen',
  'Europe/Dublin': 'EuropeDublin',
  'Europe/Gibraltar': 'EuropeGibraltar',
  'Europe/Guernsey': 'EuropeGuernsey',
  'Europe/Helsinki': 'EuropeHelsinki',
  'Europe/Isle_of_Man': 'EuropeIsle_of_Man',
  'Europe/Istanbul': 'EuropeIstanbul',
  'Europe/Jersey': 'EuropeJersey',
  'Europe/Kaliningrad': 'EuropeKaliningrad',
  'Europe/Kiev': 'EuropeKiev',
  'Europe/Kirov': 'EuropeKirov',
  'Europe/Lisbon': 'EuropeLisbon',
  'Europe/Ljubljana': 'EuropeLjubljana',
  'Europe/London': 'EuropeLondon',
  'Europe/Luxembourg': 'EuropeLuxembourg',
  'Europe/Madrid': 'EuropeMadrid',
  'Europe/Malta': 'EuropeMalta',
  'Europe/Mariehamn': 'EuropeMariehamn',
  'Europe/Minsk': 'EuropeMinsk',
  'Europe/Monaco': 'EuropeMonaco',
  'Europe/Moscow': 'EuropeMoscow',
  'Europe/Nicosia': 'EuropeNicosia',
  'Europe/Oslo': 'EuropeOslo',
  'Europe/Paris': 'EuropeParis',
  'Europe/Podgorica': 'EuropePodgorica',
  'Europe/Prague': 'EuropePrague',
  'Europe/Riga': 'EuropeRiga',
  'Europe/Rome': 'EuropeRome',
  'Europe/Samara': 'EuropeSamara',
  'Europe/San_Marino': 'EuropeSan_Marino',
  'Europe/Sarajevo': 'EuropeSarajevo',
  'Europe/Saratov': 'EuropeSaratov',
  'Europe/Simferopol': 'EuropeSimferopol',
  'Europe/Skopje': 'EuropeSkopje',
  'Europe/Sofia': 'EuropeSofia',
  'Europe/Stockholm': 'EuropeStockholm',
  'Europe/Tallinn': 'EuropeTallinn',
  'Europe/Tirane': 'EuropeTirane',
  'Europe/Tiraspol': 'EuropeTiraspol',
  'Europe/Ulyanovsk': 'EuropeUlyanovsk',
  'Europe/Uzhgorod': 'EuropeUzhgorod',
  'Europe/Vaduz': 'EuropeVaduz',
  'Europe/Vatican': 'EuropeVatican',
  'Europe/Vienna': 'EuropeVienna',
  'Europe/Vilnius': 'EuropeVilnius',
  'Europe/Volgograd': 'EuropeVolgograd',
  'Europe/Warsaw': 'EuropeWarsaw',
  'Europe/Zagreb': 'EuropeZagreb',
  'Europe/Zaporozhye': 'EuropeZaporozhye',
  'Europe/Zurich': 'EuropeZurich',
  'Hongkong': 'Hongkong',
  'Iceland': 'Iceland',
  'Indian/Antananarivo': 'IndianAntananarivo',
  'Indian/Chagos': 'IndianChagos',
  'Indian/Christmas': 'IndianChristmas',
  'Indian/Cocos': 'IndianCocos',
  'Indian/Comoro': 'IndianComoro',
  'Indian/Kerguelen': 'IndianKerguelen',
  'Indian/Mahe': 'IndianMahe',
  'Indian/Maldives': 'IndianMaldives',
  'Indian/Mauritius': 'IndianMauritius',
  'Indian/Mayotte': 'IndianMayotte',
  'Indian/Reunion': 'IndianReunion',
  'Iran': 'Iran',
  'Israel': 'Israel',
  'Jamaica': 'Jamaica',
  'Japan': 'Japan',
  'Kwajalein': 'Kwajalein',
  'Libya': 'Libya',
  'Mexico/BajaNorte': 'MexicoBajaNorte',
  'Mexico/BajaSur': 'MexicoBajaSur',
  'Mexico/General': 'MexicoGeneral',
  'Navajo': 'Navajo',
  'Pacific/Apia': 'PacificApia',
  'Pacific/Auckland': 'PacificAuckland',
  'Pacific/Bougainville': 'PacificBougainville',
  'Pacific/Chatham': 'PacificChatham',
  'Pacific/Chuuk': 'PacificChuuk',
  'Pacific/Easter': 'PacificEaster',
  'Pacific/Efate': 'PacificEfate',
  'Pacific/Enderbury': 'PacificEnderbury',
  'Pacific/Fakaofo': 'PacificFakaofo',
  'Pacific/Fiji': 'PacificFiji',
  'Pacific/Funafuti': 'PacificFunafuti',
  'Pacific/Galapagos': 'PacificGalapagos',
  'Pacific/Gambier': 'PacificGambier',
  'Pacific/Guadalcanal': 'PacificGuadalcanal',
  'Pacific/Guam': 'PacificGuam',
  'Pacific/Honolulu': 'PacificHonolulu',
  'Pacific/Johnston': 'PacificJohnston',
  'Pacific/Kiritimati': 'PacificKiritimati',
  'Pacific/Kosrae': 'PacificKosrae',
  'Pacific/Kwajalein': 'PacificKwajalein',
  'Pacific/Majuro': 'PacificMajuro',
  'Pacific/Marquesas': 'PacificMarquesas',
  'Pacific/Midway': 'PacificMidway',
  'Pacific/Nauru': 'PacificNauru',
  'Pacific/Niue': 'PacificNiue',
  'Pacific/Norfolk': 'PacificNorfolk',
  'Pacific/Noumea': 'PacificNoumea',
  'Pacific/Pago_Pago': 'PacificPago_Pago',
  'Pacific/Palau': 'PacificPalau',
  'Pacific/Pitcairn': 'PacificPitcairn',
  'Pacific/Pohnpei': 'PacificPohnpei',
  'Pacific/Ponape': 'PacificPonape',
  'Pacific/Port_Moresby': 'PacificPort_Moresby',
  'Pacific/Rarotonga': 'PacificRarotonga',
  'Pacific/Saipan': 'PacificSaipan',
  'Pacific/Samoa': 'PacificSamoa',
  'Pacific/Tahiti': 'PacificTahiti',
  'Pacific/Tarawa': 'PacificTarawa',
  'Pacific/Tongatapu': 'PacificTongatapu',
  'Pacific/Truk': 'PacificTruk',
  'Pacific/Wake': 'PacificWake',
  'Pacific/Wallis': 'PacificWallis',
  'Pacific/Yap': 'PacificYap',
  'Poland': 'Poland',
  'Portugal': 'Portugal',
  'Singapore': 'Singapore',
  'Turkey': 'Turkey',
  'US/Alaska': 'USAlaska',
  'US/Aleutian': 'USAleutian',
  'US/Arizona': 'USArizona',
  'US/Central': 'USCentral',
  'US/East-Indiana': 'USEast-Indiana',
  'US/Eastern': 'USEastern',
  'US/Hawaii': 'USHawaii',
  'US/Indiana-Starke': 'USIndiana-Starke',
  'US/Michigan': 'USMichigan',
  'US/Mountain': 'USMountain',
  'US/Pacific': 'USPacific',
  'US/Samoa': 'USSamoa',
  'Zulu': 'Zulu',

  // 'GB': 'GB',
  // 'GB-Eire': 'GB-Eire',
  // 'GMT': 'GMT',
  // 'GMT+0': 'GMT+0',
  // 'GMT-0': 'GMT-0',
  // 'GMT0': 'GMT0',
  // 'Greenwich': 'Greenwich',
  // 'HST': 'HST',
  // CET: 'CET',
  // CST6CDT: 'CST6CDT',
  // 'EET': 'EET',
  // 'EST': 'EST',
  // 'EST5EDT': 'EST5EDT',
  // 'UTC': 'UTC',
  // 'UCT': 'UCT',
  // 'ROC': 'ROC',
  // 'ROK': 'ROK',
  // 'MET': 'MET',
  // 'MST': 'MST',
  // 'MST7MDT': 'MST7MDT',
  // 'NZ': 'NZ',
  // 'NZ-CHAT': 'NZ-CHAT',
  // 'PRC': 'PRC',
  // 'PST8PDT': 'PST8PDT',
  // 'Universal': 'Universal',
  // 'W-SU': 'W-SU',
  // 'WET': 'WET',
  // 'Etc/GMT': 'EtcGMT',
  // 'Etc/GMT+0': 'EtcGMT+0',
  // 'Etc/GMT+1': 'EtcGMT+1',
  // 'Etc/GMT+10': 'EtcGMT+10',
  // 'Etc/GMT+11': 'EtcGMT+11',
  // 'Etc/GMT+12': 'EtcGMT+12',
  // 'Etc/GMT+2': 'EtcGMT+2',
  // 'Etc/GMT+3': 'EtcGMT+3',
  // 'Etc/GMT+4': 'EtcGMT+4',
  // 'Etc/GMT+5': 'EtcGMT+5',
  // 'Etc/GMT+6': 'EtcGMT+6',
  // 'Etc/GMT+7': 'EtcGMT+7',
  // 'Etc/GMT+8': 'EtcGMT+8',
  // 'Etc/GMT+9': 'EtcGMT+9',
  // 'Etc/GMT-0': 'EtcGMT-0',
  // 'Etc/GMT-1': 'EtcGMT-1',
  // 'Etc/GMT-10': 'EtcGMT-10',
  // 'Etc/GMT-11': 'EtcGMT-11',
  // 'Etc/GMT-12': 'EtcGMT-12',
  // 'Etc/GMT-13': 'EtcGMT-13',
  // 'Etc/GMT-14': 'EtcGMT-14',
  // 'Etc/GMT-2': 'EtcGMT-2',
  // 'Etc/GMT-3': 'EtcGMT-3',
  // 'Etc/GMT-4': 'EtcGMT-4',
  // 'Etc/GMT-5': 'EtcGMT-5',
  // 'Etc/GMT-6': 'EtcGMT-6',
  // 'Etc/GMT-7': 'EtcGMT-7',
  // 'Etc/GMT-8': 'EtcGMT-8',
  // 'Etc/GMT-9': 'EtcGMT-9',
  // 'Etc/GMT0': 'EtcGMT0',
  // 'Etc/Greenwich': 'EtcGreenwich',
  // 'Etc/UCT': 'EtcUCT',
  // 'Etc/UTC': 'EtcUTC',
  // 'Etc/Universal': 'EtcUniversal',
  // 'Etc/Zulu': 'EtcZulu',
};
