import React from 'react';
import Helmet from 'react-helmet';
import ErrorMessage from '@/components/Page/ErrorMessage';
import { browserTitleSufix } from '@/constants/common';


const getBrowserTitle = (pageTitle: string) => `${pageTitle} - ${browserTitleSufix}`;

interface IProps {
  children?: null | React.ReactNode,
  metaData: MetaDataObject,
  error: undefined| null | Error,
}

/**
 *  Wrapper for every page
 *  it adds title to page and displays error page if needed
 *
 */
const Page = ({
  children,
  error,
  metaData,
}: IProps) => {
  if (error) {
    return (
      <>
        <Helmet title={getBrowserTitle(metaData.title)} />
        <ErrorMessage error={error} />
      </>
    );
  }

  return (
    <>
      <Helmet title={getBrowserTitle(metaData.title)} />
      {children}
    </>
  );
};

Page.defaultProps = {
  options: null,
  children: null,
  error: null,
};

export default Page;
