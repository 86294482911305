export const companyStatuses = {
  active: 'ACTIVE',
  blocked: 'BLOCKED',
  new: 'NEW',
  unpaid: 'UNPAID',
};

export const profileTypes = {
  private: 'PRIVATE',
  b2b: 'B2B',
  company: 'COMPANY',
  corporation: 'CORPORATE',
  guest: 'GUEST',
};

export const invoiceTypes = {
  without: 'WITHOUT',
  single: 'SINGLE',
  collective: 'COLLECTIVE',
};

export const paymentMethods = {
  auto: 'AUTO',
  single: 'SINGLE',
  pp: 'PP',
};
