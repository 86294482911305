import { Role } from '@/typesAndInterfaces/role';


interface Action {
  type: string,
  operatorRoles: Role[] | null,
}

const SET_OPERATOR_ROLES = 'OPERATOR_ROLES/SET_OPERATOR_ROLES';

export default (
  state = { operatorRoles: null },
  action: Action,
) => {
  switch (action.type) {
    case SET_OPERATOR_ROLES:
      return {
        ...state,
        operatorRoles: action.operatorRoles,
      };
    default:
      return state;
  }
};

export function setOperatorRoles(operatorRoles: Role[] | null) {
  return { type: SET_OPERATOR_ROLES, operatorRoles };
}
