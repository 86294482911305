import { Tenant } from '@/typesAndInterfaces/tenant';


interface Action {
  type: string,
  tenant: Tenant,
}

export const SET_TENANT = 'API/SET_TENANT';

export default (
  state: Tenant | null = null,
  action: Action,
) => {
  switch (action.type) {
    case SET_TENANT:
      return action.tenant;
    default:
      return state;
  }
};


export function setTenant(tenant: Tenant) {
  return { type: SET_TENANT, tenant };
}
