/* eslint-disable no-console */

export function parseError(error) {
  if (error.response === undefined) {
    console.error('Request failed: Response is undefined');
    console.error(`Message: ${error.message}`);
    return error.message;
  }

  if (error.code === 'ECONNABORTED') { // timeout error code
    console.error({ ...error });
    const errorMessage = `${error.toString()}\nRequest url: ${error.config.url}`;
    console.error(`Request timeout.\n${errorMessage}`);
    return errorMessage;
  }

  if (
    error.response.headers != null
    && error.response.headers['content-type'] != null
    && error.response.headers['content-type'].startsWith('text/html')
  ) {
    console.error('Request failed: Server return text/html');
    console.error(`Message: ${error.message}`);
    return error.message;
  }

  console.error(`Request failed: ${error.response.data.error}`);
  if (typeof error.response.data.message === 'string') {
    console.error(`Message: ${error.response.data.message}`);
    return error.response.data.message;
  }
  if (typeof error.message === 'string') {
    console.error(error.message);
    return error.message;
  }
  if (typeof error.response.data.message !== 'object') {
    console.error('Unknown error');
    return '';
  }

  let message = '';
  Object.keys(error.response.data.message)
    .forEach((key) => {
      message += `${key}: ${error.response.data.message[key].join('; ')}\n`;
    });
  console.error(`'Messages:\n'${message}`);
  return message;
}
