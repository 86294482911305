import { createMuiTheme } from '@material-ui/core/styles';
import colors from './colors';


const theme = createMuiTheme({
  typography: {
    fontSize: 14,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '1.25rem',
    },
    h2: {
      fontWeight: 700,
      fontSize: '1.1rem',
    },
    h4: {
      fontWeight: 500,
      fontSize: '0.9rem',
    },
    body1: {
      fontWeight: 400,
      fontSize: '0.9rem',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '1.1rem',
    },
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'span',
        h3: 'div',
        h4: 'span',
        // @ts-ignore
        small: 'span',
        subtitle1: 'span',
        subtitle2: 'span',
        body1: 'span',
        body2: 'p',
      },
    },
  },
  palette: {
    type: 'light',
    primary: {
      // light: will be calculated from palette.primary.main,
      main: colors.primary,
      dark: colors.primary,
      contrastText: '#fff',
    },
    secondary: {
      main: colors.grey_silver,
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#fff',
    },
    // error: will use the default color
  },
});

export default theme;
