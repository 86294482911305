export const generalPermissions = {
  productsManage: 'productsManage',
  recipesView: 'recipesView',
  recipesManage: 'recipesManage',
  productionOrdersManage: 'productionOrdersManage',
  productAssembly: 'productAssembly',
  productsTest: 'productsTest',
  softVersionsView: 'softVersionsView',
  softVersionsManage: 'softVersionsManage',
  baseUsersManage: 'baseUsersManage',
  genericRolesView: 'genericRolesView',
  genericRolesManage: 'genericRolesManage',
  thirdPartiesManage: 'thirdPartiesManage',
  systemGeneratePremiumKeys: 'systemGeneratePremiumKeys',
  systemChangeSettings: 'systemChangeSettings',
  subscriptions: 'subscriptions',
  testPermision: 'testPermision', // no user has this permission
};

export const operatorPermissions = {
  operatorsView: 'operatorsView',
  operatorsManageAccount: 'operatorsManageAccount',
  operatorsEditRoles: 'operatorsEditRoles',
  operatorsDelete: 'operatorsDelete',
  operatorRolesView: 'operatorRolesView',
  operatorRolesManage: 'operatorRolesManage',
  networksView: 'networksView',
  networksManageLabels: 'networksManageLabels',
  networksManage: 'networksManage',
  networksCreate: 'networksCreate',
  labelsNetworksView: 'labelsNetworksView',
  labelsNetworksManage: 'labelsNetworksManage',
  groupsOperatorsView: 'groupsOperatorsView',
  groupsOperatorsManage: 'groupsOperatorsManage',
  dtbUserConfigView: 'dtbUserConfigView',
  dtbUserConfigManage: 'dtbUserConfigManage',
  dtbServiceAccess: 'dtbServiceAccess',
  dtbEditPremiumOptions: 'dtbEditPremiumOptions',
  operatorReportCreate: 'operatorReportCreate',
  operatorIssuesView: 'operatorIssuesView',
  operatorAllIssuesView: 'operatorAllIssuesView',
};

export const providerPermissions = {
  usersView: 'usersView',
  usersManageAccount: 'usersManageAccount',
  usersManageProviderAccount: 'usersManageProviderAccount',
  usersAdd: 'usersAdd',
  usersEditRoles: 'usersEditRoles',
  usersDelete: 'usersDelete',
  dtbRfidActions: 'dtbRfidActions',
  rfidsView: 'rfidsView',
  rfidsManage: 'rfidsManage',
  rfidsAdd: 'rfidsAdd',
  rfidsNotes: 'rfidsNotes',
  tariffsView: 'tariffsView',
  tariffsEdit: 'tariffsEdit',
  providerRolesView: 'providerRolesView',
  providerRolesManage: 'providerRolesManage',
  groupsProvidersMyGroupView: 'groupsProvidersMyGroupView',
  groupsProvidersView: 'groupsProvidersView',
  groupsProvidersManage: 'groupsProvidersManage',
  groupsProvidersMyGroupManage: 'groupsProvidersMyGroupManage',
  providerNetworksView: 'providerNetworksView',
  providerTransactionsReportView: 'providerTransactionsReportView',
  labelsUsersView: 'labelsUsersView',
  labelsUsersManage: 'labelsUsersManage',
  providerSubscriptionsView: 'providerSubscriptionsView',
  providerSubscriptionsManage: 'providerSubscriptionsManage',
  providerProfileView: 'providerProfileView',
  providerProfileEdit: 'providerProfileEdit',
  providerOperatorLabelsView: 'providerOperatorLabelsView',
  deleteRequestsManage: 'deleteRequestsManage',
};

export const profilePermissions = {
  profileView: 'profileView',
  profileEdit: 'profileEdit',
  profileUserView: 'profileUserView',
  profileUserEdit: 'profileUserEdit',
  profileUserDelete: 'profileUserDelete',
};

export const allPermissions = {
  ...generalPermissions,
  ...operatorPermissions,
  ...providerPermissions,
};
