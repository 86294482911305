import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import GenericButton from '@/components/generics/GenericButton';
import urls from '@/constants/urlsStructure';
import styles from './errorMessage.scss';


interface IProps {
  error: Error,
  disableButtons?: boolean,
}

function ErrorMessage({ error, disableButtons = false }: IProps) {
  const { t } = useTranslation(['componentsShared', 'login']);

  return (
    <div>
      <Typography
        variant="h1"
        className={styles.errorPageTitle}
        cy-selector="errorMessageTitle"
      >
        {t('componentsShared:anErrorHasOccurred')}
      </Typography>
      <div>
        <Typography variant="h4">
          {t('componentsShared:errorDetailsSectionIntroduction')}
        </Typography>
        <div className={styles.errorDetails}>
          <Typography className={styles.errorDetailsTitle}>
            {t(error.message)}
          </Typography>
          <div>
            {
              // @ts-ignore
              error.combineMessage || ''
            }
          </div>
        </div>
      </div>
      { !disableButtons && (
        <div className={styles.errorActionButtonsContainer}>
          <Link to="/">
            <GenericButton
              className={styles.actionButton}
              text={t('componentsShared:goBackToDashboard')}
              styling="black"
            />
          </Link>
          <Link to={urls.logout}>
            <GenericButton
              className={styles.actionButton}
              text={t('componentsShared:logout')}
              styling="black"
            />
          </Link>
          <GenericButton
            className={styles.actionButton}
            text={t('componentsShared:reload')}
            styling="black"
            onClick={() => window.location.reload()}
          />
        </div>
      )}
    </div>
  );
}

export default ErrorMessage;
