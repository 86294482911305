import { combineReducers } from 'redux';
import user from './user';
import globalFilters from './globalFilters';
import avatars from './avatars';
import chargingPoints from './chargingPoints';
import navigation from './navigation';
import pageSettings from './pageSettings';
import stations from './stations';
import selectedItemLabels from './selectedItemLabels';
import operatorGroups from './operatorGroups';
import operatorRoles from './operatorRoles';
import api from './api';
import profileRoles from './profileRoles';
import profiles from './profiles';
import users from './users';
import config from './config';
import tenant from './tenant';

const reducers = {
  user,
  globalFilters,
  avatars,
  chargingPoints,
  navigation,
  pageSettings,
  stations,
  selectedItemLabels,
  operatorGroups,
  operatorRoles,
  api,
  profileRoles,
  profiles,
  users,
  config,
  tenant,
};

// @ts-ignore
export default combineReducers(reducers);
