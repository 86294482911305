
interface Action {
  type: string,
  filterBarHeight: number,
  tableHeightChanged: Function,
}

interface State {
  filterBarHeight: number,
  tableHeightChanged: Function,
}

const SET_TABLE_HEIGHT_CHANGED = 'PAGE_SETTINGS/SET_TABLE_HEIGHT_CHANGED';
const SET_FILTER_BAR_HEIGHT = 'PAGE_SETTINGS/SET_FILTER_BAR_HEIGHT';


const initialState = {
  filterBarHeight: 0,
  tableHeightChanged: () => {},
};

export default (
  state: State = initialState,
  action: Action,
) => {
  switch (action.type) {
    case SET_TABLE_HEIGHT_CHANGED:
      return {
        ...state,
        tableHeightChanged: action.tableHeightChanged,
      };
    case SET_FILTER_BAR_HEIGHT:
      return {
        ...state,
        filterBarHeight: action.filterBarHeight,
      };
    case 'RESET': {
      return initialState;
    }
    default:
      return state;
  }
};

export function setTableHeightChanged(tableHeightChanged: Function) {
  return { type: SET_TABLE_HEIGHT_CHANGED, tableHeightChanged };
}

export function setFilterBarHeight(filterBarHeight: number) {
  return { type: SET_FILTER_BAR_HEIGHT, filterBarHeight };
}

