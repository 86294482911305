import { User } from '@/typesAndInterfaces/user';


interface Action {
  type: string,
  users: User[],
}

export const SET_USERS = 'USERS/SET_USERS';

const initialState = {
  users: null,
};

export default (
  state: { users: User[]|null } = initialState,
  action: Action,
) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        users: action.users,
      };
    default:
      return state;
  }
};


export function setUsers(users: User[]) {
  return { type: SET_USERS, users };
}
