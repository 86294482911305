interface Action {
  type: string,
  currency?: string,
  language?: string,
}

interface State {
  currency: string,
  language: string,
  currencyFormatter: Intl.NumberFormat | { format: (value: string|number) => string },
}

export const SET_CURRENCY = 'CONFIG/SET_CURRENCY';
export const SET_LANGUAGE = 'CONFIG/SET_LANGUAGE';

// Potrzebne, ponieważ Intl.NumberFormat domyślnie formatuje PLN w sposób <wartość> zł,
// a my chcemy mieć <wartość> PLN dla tej waluty
const getCurrencyFormatter = (currency: string, language: string) => {
  if (currency === 'PLN' && language === 'pl') {
    return ({ format: (value: string | number) => `${(+value).toFixed(2)} PLN` });
  }
  return new Intl.NumberFormat(language, { style: 'currency', currency });
};

const initialState: State = {
  currency: 'PLN',
  language: 'pl-PL',
  currencyFormatter: getCurrencyFormatter('PLN', 'pl'),
};

export default (
  state: State = initialState,
  action: Action,
) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case SET_CURRENCY:
      return {
        ...state,
        currency: action.currency,
        currencyFormatter: getCurrencyFormatter(action.currency ?? 'PLN', state.language),
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
        currencyFormatter: getCurrencyFormatter(state.currency, action.language ?? 'PL'),
      };
    default:
      return state;
  }
};
