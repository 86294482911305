import { openDB } from 'idb';

const dbPromise = openDB('keyval-store', 1, {
  // eslint-disable-next-line @typescript-eslint/typedef
  upgrade(db) {
    db.createObjectStore('keyval');
  },
});

export const idbKeyval = {
  async get(key: string) {
    return (await dbPromise).get('keyval', key);
  },
  async set(key: string, val: unknown) {
    return (await dbPromise).put('keyval', val, key);
  },
  async delete(key: string) {
    return (await dbPromise).delete('keyval', key);
  },
  async clear() {
    return (await dbPromise).clear('keyval');
  },
  async keys() {
    return (await dbPromise).getAllKeys('keyval');
  },
};
