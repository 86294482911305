import Noty from 'noty';


interface Error {
  response?: {
    data?: {
      status: number,
      message: string,
    },
  },
}

function showNoty(type: Noty.Type, text: string, error?: Error) {
  new Noty({
    type,
    text,
  }).show();
}

export default showNoty;
