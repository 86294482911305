import moment from 'moment-timezone';
import colors from '@/constants/colors';
import { allStatuses, SimplifiedStatus, simplifiedStatuses } from '@/constants/connectorStatuses';
import i18n from '@/i18n';
import { ocppActionStatuses } from '@/constants/ocppActionStatuses';
import { issueSeverities, issueStatuses } from '@/constants/issueConstants';
import { invoiceTypes, paymentMethods, profileTypes } from '@/constants/profiles';
import { OcppAction } from '@/typesAndInterfaces/ocppAction';
import { Issue } from '@/typesAndInterfaces/issue';
import { User } from '@/typesAndInterfaces/user';
import {
  dateFormat,
  dateTimeFormat,
  fallbackTimezone,
  timeFormat, timeFormatWithSeconds,
} from '@/constants/localisationData';
import { Profile } from '@/typesAndInterfaces/profile';
import { authorizationContextType } from '@/constants/authorizationContextType';
import { userStatuses } from '@/constants/userStatuses';
import { getCookie, isProfileAdmin } from './miscellaneous';


interface Address {
  address_line?: string | null,
  street?: string | null,
  possessionNumber?: string | null,
  apartmentNumber?: string | null,
  zip_code?: string | null,
  city?: string | null,
  state_province?: string | null,
  country?: string | null,
}


export function simplifyStatus(status: string | undefined): SimplifiedStatus {
  if (typeof status !== 'string') return simplifiedStatuses.unknown;
  switch (status) {
    case allStatuses.available:
      return simplifiedStatuses.available;

    case allStatuses.charging:
    case allStatuses.suspendedevse:
    case allStatuses.suspendedev:
    case allStatuses.finishing:
    case allStatuses.preparing:
      return simplifiedStatuses.charging;

    case allStatuses.reserved:
      return simplifiedStatuses.reserved;

    case allStatuses.unavailable:
      return simplifiedStatuses.unavailable;

    case allStatuses.offline:
      return simplifiedStatuses.offline;

    case allStatuses.faulted:
      return simplifiedStatuses.faulted;

    default:
      return simplifiedStatuses.unknown;
  }
}

export function getStatusDisplayText(status: string | undefined) {
  switch (simplifyStatus(status)) {
    case simplifiedStatuses.available:
      return i18n.t('common:statuses.available');
    case simplifiedStatuses.charging:
      return i18n.t('common:statuses.charging');
    case simplifiedStatuses.reserved:
      return i18n.t('common:statuses.reserved');
    case simplifiedStatuses.unavailable:
      return i18n.t('common:statuses.unavailable');
    case simplifiedStatuses.offline:
      return i18n.t('common:statuses.offline');
    case simplifiedStatuses.faulted:
      return i18n.t('common:statuses.faulted');
    case simplifiedStatuses.unknown:
    default:
      return i18n.t('common:statuses.unknown');
  }
}

export function getColorOfStatus(status: string | undefined) {
  switch (simplifyStatus(status)) {
    case simplifiedStatuses.available:
      return colors.available;

    case simplifiedStatuses.charging:
      return colors.charging;

    case simplifiedStatuses.reserved:
      return colors.reserved;

    case simplifiedStatuses.unavailable:
      return colors.unavailable;

    case simplifiedStatuses.offline:
      return colors.offline;

    case simplifiedStatuses.faulted:
      return colors.faulted;

    case simplifiedStatuses.unknown:
    default:
      return colors.unknown;
  }
}


export function getOcppActionStatus(action: OcppAction) {
  if (Object.keys(action.response).length < 1) {
    return ocppActionStatuses.none;
  }
  if (action.response.messageType === 'ERROR') {
    return ocppActionStatuses.error;
  }
  if (action.response.messageType === 'OK') {
    return ocppActionStatuses.success;
  }
  return ocppActionStatuses.unknown;
}

export function getIssueStatus(issue: Issue) {
  if (issue && Object.values(issueStatuses).includes(issue.status)) {
    return issue.status;
  }
  return null;
}

export function getIssueSeverityDisplayText(issue: Issue) {
  switch (issue.severity) {
    case issueSeverities.LOW:
      return i18n.t('common:priority.low');
    case issueSeverities.MEDIUM:
      return i18n.t('common:priority.medium');
    case issueSeverities.HIGH:
      return i18n.t('common:priority.high');
    default:
      return i18n.t('common:unknown');
  }
}


export function priorityToText(priority: string | number | undefined) {
  switch (Number(priority)) {
    case 0:
      return i18n.t('common:priority.low');
    case 1:
      return i18n.t('common:priority.medium');
    case 2:
      return i18n.t('common:priority.high');
    default:
      // eslint-disable-next-line no-console
      console.error(Error('Unknown priority'));
      return i18n.t('common:unknown');
  }
}

export function formatDate(dateTimeString: Date | number | string | undefined) {
  if (!dateTimeString) {
    return i18n.t('common:unknown');
  }
  return moment
    .tz(dateTimeString, getCookie('timezone') ?? fallbackTimezone)
    .format(dateFormat);
}

export function formatDateWithTimeZone(dateTimeString: Date | number | string | undefined) {
  if (!dateTimeString) {
    return i18n.t('common:unknown');
  }

  let cookie;
  if (getCookie('timezone') !== '') cookie = getCookie('timezone');

  return moment
    .utc(dateTimeString)
    .tz(cookie ?? fallbackTimezone)
    .format(dateFormat);
}

export function timeDifferenceInSeconds(from: Date | string, to: Date | string) {
  const fromDate = moment(from);
  const toDate = moment(to);
  if (!fromDate.isValid() || !toDate.isValid()) {
    return 0;
  }
  return toDate.diff(fromDate, 'second');
}

export function timeDifferenceInDays(from: Date | string, to: Date | string) {
  const fromDate = moment(from);
  const toDate = moment(to);
  if (!fromDate.isValid() || !toDate.isValid()) {
    return 0;
  }
  return toDate.diff(fromDate, 'day');
}


export const labelLimiter = (text: string, maxLength: number) => {
  if (maxLength === undefined) {
    const defaultLabelLength = 50;

    return text.slice(0, defaultLabelLength) + (text.length > defaultLabelLength ? '...' : '');
  }
  return text.slice(0, maxLength) + (text.length > maxLength ? '...' : '');
};

export function formatTime(dateTimeString?: Date | number | string, withSeconds: boolean = false) {
  if (!dateTimeString) {
    return i18n.t('common:unknown');
  }
  let cookie;
  if (getCookie('timezone') !== '') cookie = getCookie('timezone');
  return moment
    .utc(dateTimeString)
    .tz(cookie ?? fallbackTimezone)
    .format(withSeconds ? timeFormatWithSeconds : timeFormat);
}

export function formatDateTime(dateTimeString?: Date | number | string) {
  if (!dateTimeString) {
    return i18n.t('common:unknown');
  }
  let cookie;
  if (getCookie('timezone') !== '') cookie = getCookie('timezone');
  return moment
    .utc(dateTimeString)
    .tz(cookie ?? fallbackTimezone)
    .format(dateTimeFormat);
}

export function secondsToTimeString(seconds: string | number) {
  const secondsAsNumber = Number(seconds);
  const h = Math.floor(secondsAsNumber / 3600);
  const m = Math.ceil((secondsAsNumber % 3600) / 60);

  const displayH = h < 10 ? `0${h}` : h;
  const displayM = m < 10 ? `0${m}` : m;

  return `${displayH}:${displayM}`;
}

export function timeStringToSeconds(timeString: string) {
  const [hour, minutes] = timeString.split(':');
  return (Number(hour) * 60 * 60) + (Number(minutes) * 60);
}

export function whToKwh(
  value: number | undefined,
  decimalPlaces: number = 2,
  shouldSkipUnits: boolean = false
) {
  if (!shouldSkipUnits) {
    return value == null
      ? i18n.t('common:unknown')
      : `${(value / 1000).toFixed(decimalPlaces)} kWh`;
  }
  return value == null
    ? i18n.t('common:unknown')
    : (value / 1000).toFixed(decimalPlaces);
}

export function wToKw(
  value?: number | string,
  decimalPlaces: number = 2,
  shouldSkipUnits: boolean = false
) {
  if (value == null || isNaN(+value)) {
    return i18n.t('common:unknown');
  }
  return shouldSkipUnits ? (+value / 1000).toFixed(decimalPlaces) : `${(+value / 1000).toFixed(decimalPlaces)} kW`;
}

export function formatCurrency(value: string | number) {
  if (typeof value === 'number') {
    return value.toFixed(2);
  }
  return parseFloat(value).toFixed(2);
}

/** return kilo wats; 230 is a voltage in poland */
export function calculatePowerFromCurrentAndNumberOfPhases(current: number, numberPhases: number) {
  return ((current * numberPhases * 230) / 1000).toFixed(2);
}

export function getUserDisplayName(user: Partial<User> | undefined | null) {
  if (user == null) {
    return i18n.t('common:noUserAssigned');
  }
  if (user.user_hash != null) {
    return user.user_hash;
  }
  if (user.first_name == null && user.last_name == null) {
    switch (user.status) {
      case userStatuses.new:
        return i18n.t('common:userUnconfirmed');
      case userStatuses.blocked:
        return i18n.t('common:userBlocked');
      case userStatuses.deleted:
        return i18n.t('common:userDeleted');
      default:
        return i18n.t('common:guest');
    }
  }

  return [user.first_name, user.last_name].join(' ').trim();
}

export function formatAddress(address: Address) {
  const {
    address_line, zip_code, city, state_province, country,
  } = address;
  if (!address_line) {
    return i18n.t('common:none');
  }
  return [`${address_line},`, zip_code, city, state_province, country].filter(f => f).join(' ');
}


export function formatShortAddress(address: Address) {
  const {
    address_line, zip_code, city,
  } = address;

  switch (null) {
    case zip_code:
      return [address_line, city].filter(f => f).join(', ');
    case address_line:
      return [zip_code, city].filter(f => f).join(' ');
    case city:
      return [address_line, zip_code].filter(f => f).join(', ');
    default:
      return `${[address_line, zip_code].filter(f => f).join(', ')} ${city}`;
  }
}


export function formatAddressLine(address: Address) {
  const { street, possessionNumber, apartmentNumber } = address;
  if (!apartmentNumber) {
    return `${street} ${possessionNumber}`;
  }
  return `${street} ${possessionNumber}/${apartmentNumber}`;
}

export function restrictTextLength(text: string, restriction: number = 50) {
  return (text && text.length > restriction)
    ? `${text.slice(0, restriction - 3)}...`
    : text;
}

export function getCompanyProfileStatus(status?: string) {
  if (typeof status !== 'string') return i18n.t('common:unknown');
  switch (status.toUpperCase()) {
    case 'ACTIVE':
      return i18n.t('profilesList:active');
    case 'BLOCKED':
      return i18n.t('profilesList:blocked');
    case 'UNPAID':
      return i18n.t('profilesList:unpaid');
    default:
      return i18n.t('common:unknown');
  }
}

export function getProfileRole(profile?: Profile) {
  if (isProfileAdmin(profile)) {
    return i18n.t('common:administrator');
  }
  return i18n.t('common:user');
}

export function getProfileType(type?: { profile_type?: string, name?: string }) {
  switch (type?.profile_type) {
    case profileTypes.b2b:
      if (type?.name && type?.name.split(' ')[0].toUpperCase() === profileTypes.corporation) {
        return i18n.t('profilesList:profileTypes.corporation');
      }
      if (type?.name && type?.name.split(' ')[0].toUpperCase() === profileTypes.company) {
        return i18n.t('profilesList:profileTypes.company');
      }
      return i18n.t('common:unknown');
    case profileTypes.private:
      return i18n.t('profilesList:profileTypes.private');
    case profileTypes.guest:
      return i18n.t('profilesList:profileTypes.guest');
    default:
      return i18n.t('common:unknown');
  }
}

export function getInvoiceType(type?: string) {
  switch (type) {
    case invoiceTypes.without:
      return i18n.t('profilesList:invoiceTypes.without');
    case invoiceTypes.single:
      return i18n.t('profilesList:invoiceTypes.single');
    case invoiceTypes.collective:
      return i18n.t('profilesList:invoiceTypes.collective');
    default:
      return i18n.t('common:unknown');
  }
}

export function getPaymentMethod(method?: string) {
  switch (method) {
    case paymentMethods.single:
      return i18n.t('profilesList:paymentMethods.single');
    case paymentMethods.auto:
      return i18n.t('profilesList:paymentMethods.auto');
    case paymentMethods.pp:
      return i18n.t('profilesList:paymentMethods.pp');
    default:
      return i18n.t('common:unknown');
  }
}

export function numberWithSpaces(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function formatTechStatus(status: string) {
  return status.toLowerCase().replace('_', '');
}

export function formatCoord(coord: number | string | undefined): string | undefined {
  return (coord !== undefined && coord !== null)
    ? parseFloat((+coord).toFixed(6)).toString()
    : coord;
}

export function formatCoords(
  coordinates: { latitude?: string | number, longitude?: string | number }
) {
  const lat = formatCoord(coordinates?.latitude as number);
  const lng = formatCoord(coordinates?.longitude as number);
  if (!lat || !lng) {
    return i18n.t('common:unknown');
  }
  return `${lat}, ${lng}`;
}

export function getAuthorizationContext(context: string) {
  switch (context) {
    case authorizationContextType.localStart:
      return i18n.t('authorizations:contextTypes.localStart');
    case authorizationContextType.localStop:
      return i18n.t('authorizations:contextTypes.localStop');
    case authorizationContextType.remoteStart:
      return i18n.t('authorizations:contextTypes.remoteStart');
    case authorizationContextType.remoteStop:
      return i18n.t('authorizations:contextTypes.remoteStop');
    default:
      return i18n.t('common:unknown');
  }
}

export const getChargingTimeFormatted = (dateFrom: Date | string, dateTo: Date | string) => {
  const timeDiff = timeDifferenceInSeconds(dateFrom, dateTo);
  const minutes = Math.floor(timeDiff / 60);
  const seconds = timeDiff % 60;
  return `${minutes} min ${seconds} s ${i18n.t('transactionDetails:charging')}`;
};
