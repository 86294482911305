const API_URL = 'https://staging.enelink.site';
// const API_URL = 'https://tauron-staging.enelink.site';
// const API_URL = 'https://polenergia.enelink.site';

// const API_URL = 'http://localhost:5001';
// const API_URL = 'https://enelink-staging-proxy-func.azurewebsites.net';

function getApiUrl() {
  if (process.env.CUSTOM_BACKEND_URL != null) {
    return process.env.CUSTOM_BACKEND_URL;
  }
  if (process.env.NODE_ENV === 'development') {
    return API_URL;
  }
  return window.location.origin;
}

export const apiURL = getApiUrl();
export const googleApiKey = process.env.GOOGLE_API_KEY;
export const { TENANT } = process.env;
